import { FunctionComponent, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGContent } from "sg-layout";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { BreadCrumbAssu } from "website/components/molecules/BreadCrumbAssu/BreadCrumbAssu";
import { SectionAllerPlusLoin } from "website/components/molecules/NosSolutions/SectionAllerPlusLoin";
import {
  IconePointsCles,
  SectionPointsCles,
} from "website/components/molecules/NosSolutions/SectionPointsCles";
import { SectionTabsNosSolutions } from "website/components/molecules/NosSolutions/SectionTabsNosSolutions";
import {
  NomNosSolutionsEnum,
  ordreTuilesPage,
  TabNosSolutionsEnum,
} from "website/components/molecules/NosSolutions/utils";
import { DASHBOARD } from "website/utils/privateRoutes";
import { MentionsSolutions } from "./MentionsSolutions";

interface NosSolutionsTemplateProps {
  nom: NomNosSolutionsEnum;
  icones: IconePointsCles[];
  tabs: TabNosSolutionsEnum[];
  spanSectionPointsCles: number;
  trackingName: string;
}

const NosSolutionsTemplate: FunctionComponent<NosSolutionsTemplateProps> = (
  props: NosSolutionsTemplateProps
) => {
  const { nom, icones, tabs, spanSectionPointsCles, trackingName } = props;
  const intl = useIntl();
  const history = useHistory();
  const { trackPage, trackClick } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

  useEffect(() => {
    trackPage("nos-solutions", trackingName, "gestion-ecran");
  }, []);

  return (
    <SGContent>
      <SGMediaQuery minwidth="sm">
        <BreadCrumbAssu step="nosSolutions.per.breadCrumbAssu" />
      </SGMediaQuery>
      <SGBox span={isPhone ? 12 : 10} margin={{ bottom: "md" }}>
        <SGSpace
          size="sm"
          textalign={isPhone ? "left" : "center"}
          direction="vertical"
        >
          <SGTitle key="titre" level={1}>
            {intl.formatMessage({
              id: `nosSolutions.${nom}.titre`,
            })}
          </SGTitle>
          {nom === NomNosSolutionsEnum.ASV && (
            <SGText key="text" size="l">
              {intl.formatMessage({
                id: `nosSolutions.${nom}.description`,
              })}
            </SGText>
          )}
        </SGSpace>
      </SGBox>
      <SGBox
        padding={{ bottom: isPhone ? "xxl" : "lg" }}
        span={spanSectionPointsCles === 6 ? 8 : 12}
      >
        <SectionPointsCles
          icones={icones}
          nom={nom}
          span={spanSectionPointsCles}
        />
      </SGBox>
      <SGBox span={isPhone ? 12 : 8} padding={{ top: isPhone ? "xxl" : "lg" }}>
        <SectionTabsNosSolutions tabs={tabs} nom={nom} />
      </SGBox>
      <SGBox margin={{ top: "md", bottom: "xl" }}>
        <SGButtonGroup disableautomargin align="center">
          <SGButton
            onClick={() => {
              trackClick(
                `${trackingName}::clic-sur-retourner-a-mon-espace-retraite`
              );
              history.push(DASHBOARD);
            }}
          >
            {intl.formatMessage({
              id: `nosSolutions.${nom}.bouton.retour`,
            })}
          </SGButton>
        </SGButtonGroup>
      </SGBox>
      <SGBox margin={{ bottom: isPhone ? "lg" : "xxl" }}>
        <SGGridRow gutter={[0, isPhone ? 16 : 32]}>
          <SGGridCol span={12}>
            <SectionAllerPlusLoin
              nom={nom}
              trackingName={trackingName}
              ordreTuilesPage={ordreTuilesPage[nom]}
            />
          </SGGridCol>
          <SGGridCol span={12} sm={8}>
            <MentionsSolutions nom={nom} />
          </SGGridCol>
        </SGGridRow>
      </SGBox>
    </SGContent>
  );
};

export { NosSolutionsTemplate };

import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGInputNumber } from "sg-input";
import { SGContent, SGLayout } from "sg-layout";
import { useMediaQuery } from "sg-media-query";
import { SGRadio, SGRadioGroup } from "sg-radio";
import { SGBox } from "sg-space";
import { SGSwitch } from "sg-switch";
import { SGPrice, SGText, SGTitle } from "sg-typo";
import {
  nextStep,
  parametersUpdated,
  previousStep,
  setNewSimulation,
} from "store/simulateurEconomiesImpots/slice";
import {
  ParametresSEI,
  PlafondConjointUsageEnum,
  ProvisionEnum,
} from "store/simulateurEconomiesImpots/types";
import { getTrackProfile } from "store/simulateurEconomiesImpots/utils";
import { callTaxEconomyCeiling } from "store/simulateurPlafondEconomieImpot/actions";
import {
  ParametresPlafondEconomieImpot,
  PlafondsSEI,
} from "store/simulateurPlafondEconomieImpot/types";
import {
  convertParametresSEIToParametresPlafondEconomieImpot,
  isEqualParametresPlafondEconomieImpot,
  isJson,
} from "store/simulateurPlafondEconomieImpot/utils";
import { State } from "store/store";
import { SGButtonIntl } from "website/components/atoms/SGButtonIntl/SGButtonIntl";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { InfoTooltip } from "website/components/molecules/InfoTooltip/InfoTooltip";
import { InfoTooltipAssu } from "website/components/molecules/InfoTooltipAssu/InfoTooltipAssu";
import { anneeN, anneePrecedente } from "website/utils/date/DateUtils";

interface Ceiling {
  remainingCeiling?: number;
  setRemainingCeiling: (remainingCeiling: number) => void;
  name: string;
  errorSapiendo: string;
}

const PlafondStep: FunctionComponent = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { trackPage } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const methods = useForm({
    criteriaMode: "all",
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const { handleSubmit } = methods;

  const initCeilingSwitchChedked = () =>
    !!(
      parametresSEI.remainingCeilingN1 ||
      parametresSEI.remainingCeilingN2 ||
      parametresSEI.remainingCeilingN3 ||
      parametresSEI.remainingPartnerCeilingN1 ||
      parametresSEI.remainingPartnerCeilingN2 ||
      parametresSEI.remainingPartnerCeilingN3
    );

  const plafondUsageChoicesList: PlafondConjointUsageEnum[] = Object.values(
    PlafondConjointUsageEnum
  );

  const parametresSEI: ParametresSEI = useSelector<State, ParametresSEI>(
    (state) => state.simulateurEconomiesImpots.parameters
  );
  const plafondsSEI: PlafondsSEI = useSelector<State, PlafondsSEI>(
    (state) => state.simulateurPlafondEconomieImpot.plafondsSEI
  );
  const parameters: ParametresPlafondEconomieImpot = useSelector<
    State,
    ParametresPlafondEconomieImpot
  >((state) => state.simulateurPlafondEconomieImpot.parameters);
  const plafondsSEIError = useSelector<State, string | undefined>(
    (state) => state.simulateurPlafondEconomieImpot.plafondsSEIError
  );
  const hasFetched: boolean = useSelector<State, boolean>(
    (state) => state.simulateurEconomiesImpots.hasFetched
  );
  const [isCeilingsSwitchChecked, setIsCeilingsSwitchChecked] =
    useState<boolean>(initCeilingSwitchChedked);
  const [remainingCeilingN1, setRemainingCeilingN1] = useState(
    parametresSEI.remainingCeilingN1
  );
  const [remainingCeilingN2, setRemainingCeilingN2] = useState(
    parametresSEI.remainingCeilingN2
  );
  const [remainingCeilingN3, setRemainingCeilingN3] = useState(
    parametresSEI.remainingCeilingN3
  );
  const [remainingPartnerCeilingN1, setRemainingPartnerCeilingN1] = useState(
    parametresSEI.remainingPartnerCeilingN1
  );
  const [remainingPartnerCeilingN2, setRemainingPartnerCeilingN2] = useState(
    parametresSEI.remainingPartnerCeilingN2
  );
  const [remainingPartnerCeilingN3, setRemainingPartnerCeilingN3] = useState(
    parametresSEI.remainingPartnerCeilingN3
  );
  const [isMutualizedSwitchChecked, setIsMutualizedSwitchChecked] =
    useState<boolean>(parametresSEI.isPartnerCeilingMutualized ?? false);
  const [donePaymentSwitchChecked, setDonePaymentSwitchChecked] =
    useState<boolean>(!!parametresSEI.donePayment);
  const [donePayment, setDonePayment] = useState(parametresSEI.donePayment);
  const [partnerDonePayment, setPartnerDonePayment] = useState(
    parametresSEI.partnerDonePayment
  );
  const [usePartnerCeiling, setUsePartnerCeiling] = useState(
    parametresSEI.usePartnerCeiling
  );
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [erreurSapiendo, setErreurSapiendo] = useState<{
    [index: string]: string;
  }>();

  const [parametresPlafondEconomieImpot, setParametresPlafondEconomieImpot] =
    useState<ParametresPlafondEconomieImpot>();

  const isNotMadelin = parametresSEI.provision !== ProvisionEnum.MADELIN;

  const showIsMutualizedSwitchChecked =
    parametresSEI.isJointDeclaration &&
    isNotMadelin &&
    (usePartnerCeiling === PlafondConjointUsageEnum.NONE ||
      usePartnerCeiling === PlafondConjointUsageEnum.PARTIAL);
  const showPartnerDonePayment =
    parametresSEI.isJointDeclaration &&
    isNotMadelin &&
    usePartnerCeiling === PlafondConjointUsageEnum.PARTIAL;
  const showUsePartnerCeiling =
    parametresSEI.isJointDeclaration && isNotMadelin;

  const pastCeilings: Ceiling[] = [
    {
      remainingCeiling: remainingCeilingN1,
      setRemainingCeiling: setRemainingCeilingN1,
      name: "N1",
      errorSapiendo: "plafond_restant_n_1",
    },
    {
      remainingCeiling: remainingCeilingN2,
      setRemainingCeiling: setRemainingCeilingN2,
      name: "N2",
      errorSapiendo: "plafond_restant_n_2",
    },
    {
      remainingCeiling: remainingCeilingN3,
      setRemainingCeiling: setRemainingCeilingN3,
      name: "N3",
      errorSapiendo: "plafond_restant_n_3",
    },
  ];

  const pastPartnerCeilings: Ceiling[] = [
    {
      remainingCeiling: remainingPartnerCeilingN1,
      setRemainingCeiling: setRemainingPartnerCeilingN1,
      name: "conjoint.N1",
      errorSapiendo: "plafond_restant_conjoint_n_1",
    },
    {
      remainingCeiling: remainingPartnerCeilingN2,
      setRemainingCeiling: setRemainingPartnerCeilingN2,
      name: "conjoint.N2",
      errorSapiendo: "plafond_restant_conjoint_n_2",
    },
    {
      remainingCeiling: remainingPartnerCeilingN3,
      setRemainingCeiling: setRemainingPartnerCeilingN3,
      name: "conjoint.N3",
      errorSapiendo: "plafond_restant_conjoint_n_3",
    },
  ];

  const showErreurPlafond = useMemo(
    () =>
      !pastCeilings
        .concat(pastPartnerCeilings)
        .some(
          (ceiling: Ceiling) =>
            erreurSapiendo &&
            erreurSapiendo[ceiling.errorSapiendo] !== undefined
        ) && plafondsSEIError !== undefined,
    [erreurSapiendo, plafondsSEIError]
  );

  useEffect(() => {
    trackPage(
      "parcours-sei",
      "mon-plafond-fiscal",
      PAGE_TYPE_FORMULAIRE,
      "simulation",
      "3",
      { form_field_1: getTrackProfile(parametresSEI.tnsStatus) }
    );
  }, []);

  const handleOnSubmit = () => {
    const inclurePlafonds = isNotMadelin && isCeilingsSwitchChecked;

    const inclurePlafondsConjoint =
      parametresSEI.isJointDeclaration && inclurePlafonds;

    // Pas de Madelin car l'étape 3 lui est inaccessible
    const newParametresSEI = {
      ...parametresSEI,
      remainingCeilingN1: inclurePlafonds ? remainingCeilingN1 : undefined,
      remainingCeilingN2: inclurePlafonds ? remainingCeilingN2 : undefined,
      remainingCeilingN3: inclurePlafonds ? remainingCeilingN3 : undefined,
      remainingPartnerCeilingN1: inclurePlafondsConjoint
        ? remainingPartnerCeilingN1
        : undefined,
      remainingPartnerCeilingN2: inclurePlafondsConjoint
        ? remainingPartnerCeilingN2
        : undefined,
      remainingPartnerCeilingN3: inclurePlafondsConjoint
        ? remainingPartnerCeilingN3
        : undefined,
      isPartnerCeilingMutualized: showIsMutualizedSwitchChecked
        ? isMutualizedSwitchChecked
        : undefined,
      donePayment: donePaymentSwitchChecked ? donePayment : undefined,
      partnerDonePayment: showPartnerDonePayment
        ? partnerDonePayment
        : undefined,
      usePartnerCeiling: showUsePartnerCeiling ? usePartnerCeiling : undefined,
    };
    const paramPlafondEconomieImpot =
      convertParametresSEIToParametresPlafondEconomieImpot(newParametresSEI);
    dispatch(parametersUpdated(newParametresSEI));
    // Appel au plafond uniquement si les paramètres changent
    if (
      !isEqualParametresPlafondEconomieImpot(
        parameters,
        paramPlafondEconomieImpot
      )
    ) {
      dispatch(callTaxEconomyCeiling(paramPlafondEconomieImpot));
    }
    setParametresPlafondEconomieImpot(paramPlafondEconomieImpot);
    setIsSubmitted(true);
  };

  useEffect(() => {
    // On change d'étape si on n'a pas d'erreur
    if (
      isSubmitted &&
      plafondsSEIError === undefined &&
      hasFetched === true &&
      isEqualParametresPlafondEconomieImpot(
        parameters,
        parametresPlafondEconomieImpot
      )
    ) {
      dispatch(setNewSimulation(true));
      dispatch(nextStep());
    }
  }, [isSubmitted, plafondsSEIError, hasFetched, parameters]);

  useEffect(() => {
    if (plafondsSEIError && isJson(plafondsSEIError)) {
      setErreurSapiendo(JSON.parse(plafondsSEIError).errors);
    }
    if (plafondsSEIError !== undefined) {
      setIsSubmitted(false);
    }
  }, [plafondsSEIError]);

  /**
   * Composant box grise qui affiche le montant du plafond de l'année en cours
   * @param isPartner boolean si vrai plafond du conjoint sinon celui du client
   * @returns le composant du plafond correspondant au client ou au conjoint
   */
  const getCeilingBox = (isPartner = false) => (
    <SGGridCol span={12} sm={6}>
      <SGLayout tagName="div" background="light">
        <SGContent>
          <SGBox textalign="center">
            <SGText size="l">
              {intl.formatMessage(
                {
                  id: `simulateurEconomiesImpots.step.plafond.plafondFiscal${
                    isNotMadelin && isPartner ? ".conjoint" : ""
                  }${!isNotMadelin ? ".madelin" : ""}`,
                },
                {
                  tooltip: (
                    <InfoTooltipAssu
                      text="simulateurEconomiesImpots.step.plafond.plafondFiscal.tooltip"
                      placement={isPhone ? "top" : "right"}
                      transformations={{ anneeN, anneeN_1: anneePrecedente }}
                    />
                  ),
                  insecable: (word: string) => (
                    <span className="espaces-insecables">{word}</span>
                  ),
                  anneeN,
                  anneeN_1: anneePrecedente,
                }
              )}
            </SGText>
          </SGBox>
          <SGBox textalign="center">
            {plafondsSEIError === undefined ? (
              <SGPrice
                value={
                  isPartner
                    ? plafondsSEI.partnerCeiling?.toLocaleString("fr-FR")
                    : plafondsSEI.personalCeiling?.toLocaleString("fr-FR")
                }
                size="l"
              />
            ) : (
              <SGText weight="600">-- €</SGText>
            )}
          </SGBox>
          {showErreurPlafond && (
            <SGGridRow>
              <SGGridCol>
                <SGText color="error">
                  <SGTextIntl intlId="modal.error.message" />
                </SGText>
              </SGGridCol>
            </SGGridRow>
          )}
        </SGContent>
      </SGLayout>
    </SGGridCol>
  );

  /**
   * Composant contenant les 3 champs de saisie des plafonds des années précédentes
   * @param ceilings champs du client ou celui de son conjoint
   * @returns le composant des 3 champs de saisie correspondant au client ou au conjoint
   */
  const getPastCeilingInputs = (ceilings: Ceiling[], isPartner = false) => (
    <SGGridCol span={12} sm={6}>
      <SGGridRow>
        <SGGridCol span={12}>
          <SGBox margin={{ top: isPhone ? "xl" : "md" }}>
            <SGTitle level={3} visuallevel={4} textalign="left">
              {intl.formatMessage({
                id: `simulateurEconomiesImpots.step.plafond.plafonds.subtitle${
                  isPartner ? ".conjoint" : ""
                }`,
              })}
            </SGTitle>
          </SGBox>
        </SGGridCol>
        {ceilings.map((ceiling) => (
          <SGGridCol span={12} key={ceiling.name}>
            <SGInputNumber
              label={
                <SGText size="l">
                  {intl.formatMessage(
                    {
                      id: `simulateurEconomiesImpots.step.plafond.plafonds.${ceiling.name}`,
                    },
                    {
                      b: (word: string) => (
                        <SGText weight="600" style={{ whiteSpace: "nowrap" }}>
                          {word}
                        </SGText>
                      ),
                    }
                  )}
                </SGText>
              }
              min={0}
              max={999_999}
              size={isPhone ? "xl" : "l"}
              required={false}
              value={ceiling.remainingCeiling}
              onChange={(value: any) => {
                ceiling.setRemainingCeiling(value);
              }}
              status={
                erreurSapiendo &&
                erreurSapiendo[ceiling.errorSapiendo] &&
                "error"
              }
              validate={erreurSapiendo && erreurSapiendo[ceiling.errorSapiendo]}
            />
          </SGGridCol>
        ))}
      </SGGridRow>
    </SGGridCol>
  );

  /**
   * Composant contenant le champs de saisie des versements effectués
   * @param ceilings champs du client ou celui de son conjoint
   * @returns le composant du champs de saisie des versements effectués au client ou au conjoint
   */
  const getDonePaymentInput = (
    setValueDonePayment: (valueDonePayment: number) => void,
    valueDonePayment?: number,
    isPartner = false
  ) => (
    <SGInputNumber
      label={
        <SGText size="l">
          {intl.formatMessage({
            id: `simulateurEconomiesImpots.step.plafond.versementsEffectues${
              isPartner ? ".conjoint" : ""
            }`,
          })}
        </SGText>
      }
      min={0}
      max={999_999}
      size={isPhone ? "xl" : "l"}
      required={false}
      value={valueDonePayment}
      onChange={(value: any) => {
        setValueDonePayment(value);
      }}
      placeholder="0"
    />
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <SGGridRow>
          <SGGridCol span={12}>
            <SGTitle level={2} textalign="left">
              {intl.formatMessage({
                id: "simulateurEconomiesImpots.step.plafond",
              })}
            </SGTitle>
          </SGGridCol>
          {getCeilingBox()}
          {isNotMadelin && (
            <>
              {parametresSEI.isJointDeclaration && getCeilingBox(true)}
              <SGGridCol span={12}>
                <SGBox margin={{ top: isPhone ? "lg" : "xl" }}>
                  <SGSwitch
                    label={
                      <SGText size="l">
                        {intl.formatMessage({
                          id: "simulateurEconomiesImpots.step.plafond.plafonds.switch",
                        })}
                      </SGText>
                    }
                    checked={isCeilingsSwitchChecked === true}
                    onChange={() =>
                      setIsCeilingsSwitchChecked(!isCeilingsSwitchChecked)
                    }
                  />
                </SGBox>
              </SGGridCol>
              {isCeilingsSwitchChecked && getPastCeilingInputs(pastCeilings)}
              {isCeilingsSwitchChecked &&
                parametresSEI.isJointDeclaration &&
                getPastCeilingInputs(pastPartnerCeilings, true)}
            </>
          )}
          <SGGridCol span={12}>
            <SGBox margin={{ top: isPhone ? "lg" : "xl" }}>
              <SGSwitch
                label={
                  <SGText size="l">
                    {intl.formatMessage(
                      {
                        id: `simulateurEconomiesImpots.step.plafond.versementsEffectues.switch${
                          isNotMadelin ? "" : ".madelin"
                        }`,
                      },
                      {
                        tooltip: (
                          <InfoTooltip text="simulateurEconomiesImpots.step.revenus.versementsEffectues.modal.text" />
                        ),
                        insecable: (word: string) => (
                          <span className="espaces-insecables">{word}</span>
                        ),
                        anneeN,
                      }
                    )}
                  </SGText>
                }
                checked={donePaymentSwitchChecked}
                onChange={() =>
                  setDonePaymentSwitchChecked(!donePaymentSwitchChecked)
                }
              />
            </SGBox>
          </SGGridCol>
          {showUsePartnerCeiling && (
            <SGGridCol span={12}>
              <SGRadioGroup
                legend={
                  <SGText size="l">
                    {intl.formatMessage({
                      id: `simulateurEconomiesImpots.step.plafond.plafondUsage`,
                    })}
                  </SGText>
                }
                value={usePartnerCeiling}
                onChange={(event: any) => {
                  setUsePartnerCeiling(event.target.value);
                }}
              >
                {plafondUsageChoicesList.map((radio: string) => (
                  <SGRadio value={radio} key={radio}>
                    <SGText size="s">
                      {intl.formatMessage({
                        id: `simulateurEconomiesImpots.step.plafond.plafondUsage.radio.${radio}`,
                      })}
                    </SGText>
                  </SGRadio>
                ))}
              </SGRadioGroup>
            </SGGridCol>
          )}
          <SGGridCol span={12}>
            <SGBox margin={{ top: isPhone ? "xs" : "sm" }}>
              <SGGridRow>
                {donePaymentSwitchChecked && (
                  <SGGridCol span={12} sm={6}>
                    {getDonePaymentInput(setDonePayment, donePayment)}
                  </SGGridCol>
                )}
                {showPartnerDonePayment && (
                  <SGGridCol span={12} sm={6}>
                    {getDonePaymentInput(
                      setPartnerDonePayment,
                      partnerDonePayment,
                      true
                    )}
                  </SGGridCol>
                )}
              </SGGridRow>
            </SGBox>
          </SGGridCol>
          {showIsMutualizedSwitchChecked && (
            <SGGridCol span={12}>
              <SGBox margin={{ top: "lg" }}>
                <SGSwitch
                  label={
                    <SGText size="l">
                      {intl.formatMessage({
                        id: "simulateurEconomiesImpots.step.plafond.mutualisation.switch",
                      })}
                    </SGText>
                  }
                  checked={isMutualizedSwitchChecked}
                  onChange={() =>
                    setIsMutualizedSwitchChecked(!isMutualizedSwitchChecked)
                  }
                />
              </SGBox>
            </SGGridCol>
          )}
          <SGGridCol span={12}>
            <SGBox margin={{ top: isPhone ? "lg" : "xxl" }}>
              <SGButtonGroup
                layout={isPhone ? "column" : "row"}
                align={isPhone ? "center" : "opposite"}
              >
                <SGButton
                  onClick={handleOnSubmit}
                  disabled={
                    !(
                      usePartnerCeiling ||
                      !parametresSEI.isJointDeclaration ||
                      !isNotMadelin
                    )
                  }
                  style={!isPhone ? { order: 1 } : {}}
                >
                  {intl.formatMessage({ id: "tunnel.buttons.next" })}
                </SGButton>
                <SGButtonIntl
                  type="link"
                  cypressName="tunnel-previous"
                  onClick={() => dispatch(previousStep())}
                  intlId="tunnel.buttons.previous"
                />
              </SGButtonGroup>
            </SGBox>
          </SGGridCol>
        </SGGridRow>
      </form>
    </FormProvider>
  );
};

export { PlafondStep };

import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { SGAvenirStyledPensionsRentes } from "sg-icon-pack-base";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFeatures } from "website/components/hooks/useFeatures";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";
import { SIMULATEUR_ECONOMIES_IMPOTS } from "website/utils/privateRoutes";

interface CarteSimulateurEconomieImpotProps {
  trackingName: string;
}

const CarteSimulateurEconomieImpot: FunctionComponent<
  CarteSimulateurEconomieImpotProps
> = (props: CarteSimulateurEconomieImpotProps) => {
  const { trackingName } = props;
  const history = useHistory();
  const { trackClick } = useTracking();
  const { parcoursSEIActif } = useFeatures();

  const handleOnClickSEILink = () => {
    trackClick(`${trackingName}::clic-sur-simuler-mon-economie-d-impot`);
    history.push(SIMULATEUR_ECONOMIES_IMPOTS);
  };

  return (
    <>
      {parcoursSEIActif && (
        <TuileCliquable
          accessible
          icone={<SGAvenirStyledPensionsRentes />}
          iconeTaille="s"
          titre={
            <FormattedMessage id="sectionAllerPlusLoin.tuile.economieImpot.titre" />
          }
          texte={
            <FormattedMessage id="sectionAllerPlusLoin.tuile.economieImpot.texte" />
          }
          onClick={handleOnClickSEILink}
          ariaLabel="Rediriger vers le parcours simulation d’économie d’impôt."
          alignchildren="center"
        />
      )}
    </>
  );
};

export { CarteSimulateurEconomieImpot };

import React, { ChangeEvent, FunctionComponent, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { SGAccordion, SGAccordionPanel } from "sg-accordion";
import { SGButton } from "sg-button";
import { SGCheckBox } from "sg-checkbox";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavCorbeille } from "sg-icon-pack-base";
import { SGInputNumber } from "sg-input";
import { useMediaQuery } from "sg-media-query";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGText } from "sg-typo";
import { Projection } from "store/parcoursRisProjection/types";
import { WorkIncomeRaisesType } from "store/workIncomesHistory/types";
import { RadioGroup } from "website/components/atoms/RadioGroup/RadioGroup";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { InfoTooltipAssu } from "website/components/molecules/InfoTooltipAssu/InfoTooltipAssu";
import { InputNumberBrutNet } from "../InputNumberBrutNet/InputNumberBrutNet";
import "./StatutCard.scss";

enum controllerName {
  REMUNERATION = "remuneration",
  SELECTCLASSEAVOCAT = "selectClasseAvocat",
  PENTE = "pente",
}

type StatutCardProps = {
  card: Projection;
  deleteCard: (statutCard: number) => void;
};

export const StatutCard: FunctionComponent<StatutCardProps> = (
  props: React.PropsWithChildren<StatutCardProps>
) => {
  const { card, deleteCard } = props;

  const [typePente, setTypePente] = useState<string>();

  const intl = useIntl();
  const methods = useFormContext();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "md" });

  const headerLabel = intl.formatMessage({
    id: `parcoursRIS.projection.statut.${card.statut}`,
  });

  const avocatClasse = ["C1", "C2", "C3", "C4", "C4+"];

  const minIncomes = 1000;

  const errorTypes: { [char: string]: string } = {
    required: "tunnel.error.required",
    min: "parcoursRIS.projection.error.min",
  };

  const penteValues = [
    {
      pente: "stable",
      label: intl.formatMessage({
        id: "parcoursRIS.projection.card.pente.stable",
      }),
      type: WorkIncomeRaisesType.NO_WORK_INCOME_RAISES,
      value: 0,
    },
    {
      pente: "assezfort",
      label: intl.formatMessage({
        id: "parcoursRIS.projection.card.pente.assezfort",
      }),
      type: WorkIncomeRaisesType.LOW_WORK_INCOME_RAISES,
      value: 1.5,
    },
    {
      pente: "fort",
      label: intl.formatMessage({
        id: "parcoursRIS.projection.card.pente.fort",
      }),
      type: WorkIncomeRaisesType.HIGH_WORK_INCOME_RAISES,
      value: 3,
    },
  ];
  const futureForecastList: WorkIncomeRaisesType[] =
    Object.values(WorkIncomeRaisesType);

  const [situationParticuliereChecked, setSituationParticuliereChecked] =
    useState(false);

  const [currentCard, setCurrentCard] = useState<Projection>(card);

  const handleChangeTauxArrco = (value: number) => {
    setCurrentCard((prevstate) => ({
      ...prevstate,
      taux_arrco_special: value,
    }));
    card.taux_arrco_special = value;
  };

  const handleChangeRemunerationAnnuelle = (value: number) => {
    setCurrentCard((prevstate) => ({
      ...prevstate,
      remuneration_annuelle: value,
    }));
    card.remuneration_annuelle = value;
  };

  const handleChangeRemunerationMensuelle = (value: number) => {
    setCurrentCard((prevstate) => ({
      ...prevstate,
      remuneration_mensuelle: value,
    }));
    card.remuneration_mensuelle = value;
  };

  const handleChangePente = (value: string) => {
    const penteValue = penteValues.find((pente) => pente.type === value)?.value;
    setTypePente(value);
    setCurrentCard((prevstate) => ({ ...prevstate, pente: penteValue }));
    card.pente = penteValue;
  };

  const handleChangePrimeMensuelle = (value: number) => {
    setCurrentCard((prevstate) => ({ ...prevstate, prime_mensuelle: value }));
    card.prime_mensuelle = value;
  };

  const onSelectClasseAvocat = (value: string) => {
    setCurrentCard((prevstate) => ({
      ...prevstate,
      classe: value,
    }));
    card.classe = value.toLocaleLowerCase();
  };

  const handleChangeCavecSalarie = (value: boolean) => {
    setCurrentCard((prevstate) => ({
      ...prevstate,
      cavec_salarie: value,
    }));
    card.cavec_salarie = value;
  };

  const handleChangeSituationParticuliere = () => {
    setSituationParticuliereChecked(!situationParticuliereChecked);
    if (!situationParticuliereChecked) {
      setCurrentCard((prevstate) => ({ ...prevstate, taux_arrco_special: 0 }));
      card.taux_arrco_special = 0;
    }
  };

  return (
    <SGAccordion
      disableautomargin
      expandIconPosition="right"
      defaultActiveKey={[headerLabel]}
      className="statut-card"
    >
      <SGAccordionPanel
        key={headerLabel}
        header={
          <SGBox margin={!isPhone && { left: "sm", right: "sm" }}>
            <SGText size="l">{headerLabel}</SGText>
          </SGBox>
        }
        tile
        disabled
        showArrow={false}
      >
        <SGBox margin={!isPhone && { left: "sm", right: "sm" }}>
          <SGSpace direction="vertical" size="xl" disableautomargin>
            <SGDivider key="divider" />
            {currentCard.statut !== 6 && currentCard.statut !== 7 && (
              <InputNumberBrutNet
                key="input-salaire"
                name={`${controllerName.REMUNERATION}${card.statut}`}
                min={minIncomes}
                max={99_999_999}
                label="parcoursRIS.projection.card.subtitle1"
                extra={
                  currentCard.statut === 20 ? (
                    <InfoTooltipAssu
                      text="parcoursRIS.projection.tooltip.info.statut.20"
                      placement={isPhone ? "left" : "bottomLeft"}
                    />
                  ) : undefined
                }
                value={currentCard.remuneration_annuelle}
                onChange={handleChangeRemunerationAnnuelle}
                brutNet={`${
                  currentCard.statut === 1 ||
                  currentCard.statut === 2 ||
                  currentCard.statut === 3
                    ? "brutan"
                    : "netan"
                }`}
              />
            )}
            {(currentCard.statut === 6 || currentCard.statut === 7) && ( // Fonctionnaires
              <InputNumberBrutNet
                key="input-salaire-fonctionnaire"
                name={`${controllerName.REMUNERATION}${card.statut}`}
                min={minIncomes}
                max={99_999_999}
                label="parcoursRIS.projection.card.subtitle.fonctionnaire.etat.traitementIndiciaire"
                value={currentCard.remuneration_mensuelle}
                onBlur={() =>
                  !currentCard.remuneration_mensuelle &&
                  handleChangeRemunerationMensuelle(0)
                }
                onChange={handleChangeRemunerationMensuelle}
                brutNet="brutmois"
              />
            )}
            {(currentCard.statut === 6 || currentCard.statut === 7) && ( // Fonctionnaires
              <InputNumberBrutNet
                key="input-salaire-prime"
                name={`${controllerName.REMUNERATION}.prime${card.statut}`}
                required={false}
                min={0}
                max={99_999_999}
                label="parcoursRIS.projection.card.subtitle.fonctionnaire.etat.primeMensuelle"
                value={currentCard.prime_mensuelle}
                onBlur={() =>
                  !currentCard.prime_mensuelle && handleChangePrimeMensuelle(0)
                }
                onChange={handleChangePrimeMensuelle}
                brutNet="brutmois"
              />
            )}

            {currentCard.statut === 8 && (
              // Séparé car SGSpace considère le fragment comme son enfant (et non plus les enfants du fragment)
              <SGBlock key="block-avocat">
                    <Controller
                      control={methods.control}
                      name={`${controllerName.SELECTCLASSEAVOCAT}${card.statut}`}
                      rules={{ required: true }}
                      render={({ onChange }) => (
                        <SGSelect
                          size={isPhone ? "xl" : "m"}
                          disableautomargin
                          placeholder="Sélectionnez"
                          value={card.classe || ""}
                          onChange={(value: string) => {
                            onChange(value);
                            onSelectClasseAvocat(value);
                          }}
                          name="selectClasseAvocat"
                          label={
                            <SGText size="l">
                              <FormattedMessage id="parcoursRIS.projection.card.subtitle.avocat" />
                            </SGText>
                          }
                          status={
                            methods.errors &&
                            methods.errors[
                              `${controllerName.SELECTCLASSEAVOCAT}${card.statut}`
                            ] &&
                            "error"
                          }
                          validate={intl.formatMessage({
                            id: "tunnel.error.required",
                          })}
                        >
                          <SGSelectOption value="" style={{ display: "none" }}>
                            Sélectionnez
                          </SGSelectOption>
                          {avocatClasse.map((classe: string) => (
                            <SGSelectOption key={classe} value={classe}>
                              <FormattedMessage
                                id={`parcoursRIS.projection.card.avocat.classe.${classe}`}
                              />
                            </SGSelectOption>
                          ))}
                        </SGSelect>
                      )}
                    />
              </SGBlock>
            )}

            <SGBlock key="block-futur">
              <SGGridRow justify="start">
                <RadioGroup
                  title="parcoursRIS.projection.card.subtitle2"
                  radioGroupList={futureForecastList}
                  selectedValue={typePente || ""}
                  setValue={handleChangePente}
                  name={`${controllerName.PENTE}${card.statut}`}
                  orientation="vertical"
                />
              </SGGridRow>
            </SGBlock>

            {currentCard.statut === 2 && (
              <SGBlock key="block-situation-particuliere">
                <SGCheckBox
                  disableautomargin
                  onChange={handleChangeSituationParticuliere}
                  checked={situationParticuliereChecked}
                >
                  <SGText size="m" weight="600">
                    <FormattedMessage id="parcoursRIS.projection.card.subtitle3" />
                  </SGText>
                </SGCheckBox>
                {situationParticuliereChecked && (
                  <SGBox margin={{ top: "xs" }}>
                    <SGInputNumber
                      size={isPhone ? "xl" : "m"}
                      label={
                        <SGTextIntl
                          intlId="parcoursRIS.projection.card.subtitle4"
                          size="l"
                        />
                      }
                      value={currentCard.taux_arrco_special}
                      min={0}
                      max={100}
                      unitvalue="%"
                      onChange={handleChangeTauxArrco}
                      onBlur={() =>
                        !currentCard.taux_arrco_special &&
                        handleChangeTauxArrco(0)
                      }
                    />
                  </SGBox>
                )}
              </SGBlock>
            )}

            {currentCard.statut === 9 && (
              // Expert comptable
              <SGBlock key="block-expert-comptable">
                <SGCheckBox
                  disableautomargin
                  checked={currentCard.cavec_salarie}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleChangeCavecSalarie(event.target.checked)
                  }
                >
                  <SGText size="m" weight="600">
                    <FormattedMessage id="parcoursRIS.projection.card.subtitle.expertcomptable" />
                  </SGText>
                </SGCheckBox>
              </SGBlock>
            )}

            <SGBox key="box-supprimer" textalign="right">
              <SGButton
                type="icon"
                icon={<SGIcon size="l" component={<SGAvenirNavCorbeille />} />}
                onClick={() => deleteCard(card.statut)}
                ariaLabel={intl.formatMessage(
                  { id: "parcoursRIS.projection.supprimer.card.ariaLabel" },
                  { statut: headerLabel }
                )}
              />
            </SGBox>
          </SGSpace>
        </SGBox>
      </SGAccordionPanel>
    </SGAccordion>
  );
};

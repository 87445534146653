import { FunctionComponent } from "react";
import { SGCard } from "sg-card";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFleche } from "sg-icon-pack-base";
import { SGText, SGTitle } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { FormattedMessage, useIntl } from "react-intl";
import { Image } from "website/components/atoms/Image/Image";
import { useHistory } from "react-router";
import { MES_SERVICES } from "website/utils/privateRoutes";
import { SGButton } from "sg-button";

const CarteOffreSapiendo: FunctionComponent = () => {
  const { trackClick } = useTracking();
  const intl = useIntl();
  const history = useHistory();

  const handleOnClick = () => {
    trackClick("clic-sur-sapiendo-expert-retraite");
    history.push(MES_SERVICES);
  };

  return (
    <>
      <SGCard
        clickable
        coversize="sm"
        layout="row"
        coverposition="leftCenter"
        cover={<Image alt="Offre Sapiendo" src="offreSapiendo.png" />}
        actions={[
          <SGButton
            icon={<SGIcon component={<SGAvenirNavFleche />} />}
            type="icon"
            onClick={handleOnClick}
          />,
        ]}
      >
        <SGTitle key="title" level={2} visuallevel={5}>
          <FormattedMessage id="carteOffreSapiendo.title" />
        </SGTitle>
        <SGText key="text" size="s">
          {intl.formatMessage({ id: `carteOffreSapiendo.text` })}
        </SGText>
      </SGCard>
    </>
  );
};

export { CarteOffreSapiendo };

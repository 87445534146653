import { useMemo } from "react";
import { useSelector } from "react-redux";
import { State } from "store/store";

// Liste des sous segments non autorisés
const lstSousSegments = ["12", "13"];

export const useDroitsSegment = () => {
  const potentielSousSegmentNSM: string = useSelector<State, string>(
    (state) => state.account.account.potentielSousSegmentNSM
  );

  const isDiagnosticEpargneAvailable = useMemo(
    () => !lstSousSegments.includes(potentielSousSegmentNSM),
    [potentielSousSegmentNSM]
  );

  return { isDiagnosticEpargneAvailable };
};

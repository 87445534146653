import { FunctionComponent, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SGAlert } from "sg-alert";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGContent, SGHeader, SGLayout } from "sg-layout";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGStep, SGSteps } from "sg-steps";
import { SGText, SGTitle } from "sg-typo";
import {
    SupervisionState,
    possedeDroitsActionUtilisateur,
} from "store/supervision/types";
import { resetTunnelAction } from "store/tunnel/actions";
import { PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useAccount } from "website/components/hooks/useAccount";
import { useFamily } from "website/components/hooks/useFamily";
import { usePersonalInformations } from "website/components/hooks/usePersonalInformations";
import { useScrollToTopWithCurrentStep } from "website/components/hooks/useScrollToTopWithCurrentStep";
import { trackingEvent, useTrackingEvent } from "website/components/hooks/useTrackingEvent";
import { TunnelWelcome } from "website/components/organisms/Tunnel/TunnelAssu/TunnelWelcome/TunnelWelcome";
import { TunnelWorkIncomes } from "website/components/organisms/Tunnel/TunnelAssu/TunnelWorkIncomes/TunnelWorkIncomes";
import { TunnelWorkIncomesForecast } from "website/components/organisms/Tunnel/TunnelAssu/TunnelWorkIncomesForecast/TunnelWorkIncomesForecast";
import { DetailTabProvider } from "website/components/providers/DetailTabProvider";
import { LOGOUT } from "website/utils/privateRoutes";
import { State } from "../../store/store";
import { TunnelFamilySituation as TunnelFamilySituationAssu } from "../components/organisms/Tunnel/TunnelAssu/TunnelFamilySituation/TunnelFamilySituation";
import { TunnelAssu } from "../components/templates/TunnelAssu/TunnelAssu";

export const TunnelPage: FunctionComponent = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const saveEvent = useTrackingEvent();
    const history = useHistory();
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    // On masque le tunnel tant que la popin n'est pas fermée
    const { trackPage } = useTracking();

    const supervisionState: SupervisionState = useSelector<State, SupervisionState>((state) => state.supervision);

    // Fetch pour plus tard
    const account = useAccount();
    const family = useFamily();
    const { personalInformationsState } = usePersonalInformations();

    const currentStep = useSelector<State, number>((state) => state.tunnel.currentStep);
    const isTunnelReforme = useSelector<State, boolean>((state) => state.tunnel.isTunnelReforme);

    const steps = [TunnelWelcome, TunnelFamilySituationAssu, TunnelWorkIncomes, TunnelWorkIncomesForecast];

    const categories = [
        intl.formatMessage({ id: "tunnel.step.etape1" }),
        intl.formatMessage({ id: "tunnel.step.etape2" }),
        intl.formatMessage({ id: "tunnel.step.etape3" }),
        intl.formatMessage({ id: "tunnel.step.etape4" }),
    ];

    useEffect(() => {
        trackPage("parcours-onboarding", "ma-retraite", PAGE_TYPE_FORMULAIRE, "inscription", "1");
        saveEvent(trackingEvent.ONBOARDING_MA_RETRAITE);
        dispatch(resetTunnelAction());
    }, []);

    useScrollToTopWithCurrentStep(currentStep);

    const getStatus = (index: number): string => {
        if (currentStep === index) {
            return "process";
        }
        if (currentStep < index) {
            return "wait";
        }

        return "finish";
    };

    const handleLogout = () => {
        history.push(LOGOUT);
    };

        return (
        <SGLayout tagName="div">
        <SGContent disableautomargin>
          <SGGridRow justify="center" gutter={[0, 20]}>
            <SGGridCol span={12}>
                {!possedeDroitsActionUtilisateur(supervisionState) &&
                    <SGAlert
                        description={<SGText size="m">Le client n’a pas réalisé son onboarding, invitez-le à découvrir Mon&#160;Compagnon&#160;Retraite.</SGText>}
                        icon={<SGIcon component={<SGAvenirStatusInfo />} size="l" />}
                        type="info"
                        span={9}
                        action={<SGButton size="small" onClick={handleLogout}>Retour</SGButton>}
                        actionPlacement="top"
                    />
                }
            </SGGridCol>
            <SGMediaQuery minwidth="sm">
                <SGGridCol span={12}>
                    <SGBox margin={{top: isPhone ? "lg" : "sm"}}>
                        <SGTitle textalign="center" caps level={2}>
                            {intl.formatMessage({ id: isTunnelReforme && currentStep !== 0 ? "tunnel.reforme.title" : "tunnel.title" })}
                        </SGTitle>
                    </SGBox>
                </SGGridCol>
            </SGMediaQuery>
            <SGGridCol span={12}>
                <SGBox margin={{bottom: "md"}}>
                    <SGSteps current={currentStep}>
                        {categories.map((categorie, index) => (
                            <SGStep title={categorie} status={getStatus(index)} key={categorie} />
                        ))}
                    </SGSteps>
                </SGBox>
            </SGGridCol>
          </SGGridRow>
        </SGContent>
        <SGLayout background="light">
          <SGHeader disableautomargin />
          <SGContent disableautomargin>
            <DetailTabProvider>
                <TunnelAssu steps={steps} />
            </DetailTabProvider>
          </SGContent>
        </SGLayout>
      </SGLayout>
    )
};

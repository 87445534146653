import { FunctionComponent, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { useMediaQuery } from "sg-media-query";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { ImportState } from "store/import/types";
import { RetirementCategorySimulation } from "store/members/types";
import { RetirementSimulationParametersState } from "store/simulationAssu/types";
import { State } from "store/store";
import { previousStepAction } from "store/tunnel/actions";
import { fetchWorkIncomesHistory } from "store/workIncomesHistory/action";
import { WorkIncomesHistory, WorkIncomesHistoryState } from "store/workIncomesHistory/types";
import { useFamily } from "website/components/hooks/useFamily";
import { useFeatures } from "website/components/hooks/useFeatures";
import { InfoAssuModal } from "website/components/molecules/InfoAssuModal/InfoAssuModal";
import { InputNumberBrutNet } from "website/components/molecules/InputNumberBrutNet/InputNumberBrutNet";

interface WorkIncomesProps {
    onSubmit: (data: WorkIncomesHistory) => void;
    showPopin: boolean;
    onHideModal: () => void;
    carrierStartMonth?: number;
    setCarrierStartMonth: (value: number) => void;
    carrierStartYear?: number;
    setCarrierStartYear: (value: number) => void;
    montantSalaireAnnuel?: number;
    setMontantSalaireAnnuel: (value: number | undefined) => void;
    secteurPro?: string;
    setSecteurPro: (value: string | undefined) => void;
    setMemberId: (value: number | undefined) => void;
}

export const WorkIncomes: FunctionComponent<WorkIncomesProps> = (props: WorkIncomesProps) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const {
        onSubmit,
        showPopin,
        onHideModal,
        carrierStartMonth,
        setCarrierStartMonth,
        carrierStartYear,
        setCarrierStartYear,
        montantSalaireAnnuel,
        setMontantSalaireAnnuel,
        secteurPro,
        setSecteurPro,
        setMemberId,
    } = props;

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

    const family = useFamily();

    const workIncomesHistoryState: WorkIncomesHistoryState = useSelector<State, WorkIncomesHistoryState>((state) => state.workIncomesHistory);
    const importData: ImportState = useSelector<State, ImportState>((state) => state.importData);
    const simulationAssuState: RetirementSimulationParametersState = useSelector<State, RetirementSimulationParametersState>(
        (state) => state.retirementSimulationParameters
    );

    const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

    const [revenuAnuel, setRevenuAnuel] = useState<string>();
    const [options, setOptions] = useState([]);

    const isBrutan = secteurPro === RetirementCategorySimulation.SALARIE_PRIVE_CADRE || secteurPro === RetirementCategorySimulation.FONCTIONNAIRE;

    const methods = useForm({
        criteriaMode: "all",
        mode: "onSubmit",
        reValidateMode: "onChange",
    });
    const { handleSubmit, formState } = methods;

    useEffect(() => {
        dispatch(fetchWorkIncomesHistory());
    }, []);

    useEffect(() => {
        methods.setValue("carrierStartMonth", carrierStartMonth, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [carrierStartMonth]);

    useEffect(() => {
        methods.setValue("carrierStartYear", carrierStartYear, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [carrierStartYear]);

    useEffect(() => {
        methods.setValue("montantSalaireAnnuel", montantSalaireAnnuel, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [montantSalaireAnnuel]);

    useEffect(() => {
        setSecteurPro(simulationAssuState.retirementSimulationParameters.secteurPro);
    }, [simulationAssuState]);

    useEffect(() => {
        if (secteurPro) {
            if (secteurPro === RetirementCategorySimulation.SALARIE_PRIVE_CADRE) {
                setRevenuAnuel("revenuAnnuelSalarie");
            } else if (secteurPro === RetirementCategorySimulation.FONCTIONNAIRE) {
                setRevenuAnuel("revenuAnnuelFonctionnaire");
            } else {
                setRevenuAnuel("revenuAnnuel");
            }
        }
    }, [secteurPro]);

    useEffect(() => {
        if (family?.me) {
            const birthday = new Date(family.me.birthday);
            setMemberId(family.me.id);

            const minYear = birthday.getFullYear() + 16;
            const thisYear = new Date().getFullYear();
            const maxOffset = thisYear - minYear;

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const optionsSelect: any = [];
            for (let i = 0; i <= maxOffset; i += 1) {
                const year = thisYear - i;
                optionsSelect.push(
                    <SGSelectOption key={i} value={year.toString()}>
                        {year}
                    </SGSelectOption>
                );
            }
            setOptions(optionsSelect.reverse());
        }
        if (workIncomesHistoryState && workIncomesHistoryState.workIncomesHistory) {
            setMontantSalaireAnnuel(workIncomesHistoryState.workIncomesHistory.currentYearlyAmount);
            if (workIncomesHistoryState.workIncomesHistory.carrierStartDate) {
                setCarrierStartMonth(new Date(workIncomesHistoryState.workIncomesHistory.carrierStartDate).getMonth() + 1);
                setCarrierStartYear(new Date(workIncomesHistoryState.workIncomesHistory.carrierStartDate).getFullYear());
            }
        }
    }, [family, importData, workIncomesHistoryState]);

    function getErrorMontantSalaireAnnuel() {
        const errorTypes: { [char: string]: string } = {
            required: "tunnel.error.required",
            min: "tunnel.assu.error.min",
        };
        const error = methods.errors?.montantSalaireAnnuel;

        return error !== undefined && error?.type in errorTypes ? intl.formatMessage({ id: errorTypes[error?.type] }) : "";
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <SGTitle level={2} visuallevel={3}>
                    {intl.formatMessage({ id: "tunnel.workIncomesHistory.title" })}
                </SGTitle>
                <SGSpace direction="vertical" size="lg">
                    <SGText size="l" key="text">
                        <FormattedMessage id="tunnel.workIncomesHistory.startWork" />
                    </SGText>
                    <SGSpace disableautomargin key="space">
                        <Controller
                            key="carrierStartMonth"
                            control={methods.control}
                            name="carrierStartMonth"
                            rules={{
                                required: true,
                            }}
                            render={({ onChange }) => (
                                <SGSelect
                                    placeholder="Sélectionnez"
                                    label={
                                        <SGText>
                                            <FormattedMessage id="tunnel.workIncomesHistory.mois" />
                                        </SGText>
                                    }
                                    value={carrierStartMonth?.toString() || ""}
                                    onChange={(value: number) => {
                                        onChange(value);
                                        setCarrierStartMonth(value);
                                    }}
                                    status={methods?.errors?.carrierStartMonth && "error"}
                                    validate={intl.formatMessage({ id: "tunnel.error.required" })}
                                >
                                    <SGSelectOption value="" style={{ display: "none" }}>
                                        Sélectionnez
                                    </SGSelectOption>
                                    {months.map((month: string, index: number) => (
                                        <SGSelectOption key={month} value={(index + 1).toString()}>
                                            {month}
                                        </SGSelectOption>
                                    ))}
                                </SGSelect>
                            )}
                        />
                        <Controller
                            key="carrierStartYear"
                            control={methods.control}
                            name="carrierStartYear"
                            rules={{
                                required: true,
                            }}
                            render={({ onChange }) => (
                                <SGSelect
                                    defaultValue="Sélectionnez"
                                    label={
                                        <SGText>
                                            <FormattedMessage id="tunnel.workIncomesHistory.année" />
                                        </SGText>
                                    }
                                    value={carrierStartYear?.toString() || ""}
                                    onChange={(value: number) => {
                                        onChange(value);
                                        setCarrierStartYear(value);
                                    }}
                                    status={methods?.errors?.carrierStartYear && "error"}
                                    validate={intl.formatMessage({ id: "tunnel.error.required" })}
                                >
                                    <SGSelectOption value="" style={{ display: "none" }}>
                                        Sélectionnez
                                    </SGSelectOption>
                                    {options}
                                </SGSelect>
                            )}
                        />
                    </SGSpace>
                    <InputNumberBrutNet
                        key="input-salaire"
                        name="montantSalaireAnnuel"
                        min={1000}
                        max={99_999_999}
                        label="parcoursRIS.projection.card.subtitle1"
                        value={montantSalaireAnnuel}
                        onChange={setMontantSalaireAnnuel}
                        brutNet={isBrutan ? "brutan" : "netan"}
                    />
                </SGSpace>
                <SGBox margin={{ top: "xl" }}>
                    <SGButtonGroup align={isPhone ? "center" : "opposite"} disableautomargin>
                        <SGButton type="primary" onClick={handleSubmit(onSubmit)} size={[isPhone ? "md" : "sm", "md"]}>
                            {intl.formatMessage({ id: "tunnel.buttons.next" })}
                        </SGButton>
                        <SGButton type="link" onClick={() => dispatch(previousStepAction())}>
                            <SGText>{intl.formatMessage({ id: "tunnel.buttons.previous" })}</SGText>
                        </SGButton>
                    </SGButtonGroup>
                </SGBox>
                <InfoAssuModal
                    onHide={onHideModal}
                    showInfoModal={showPopin}
                    text="popin.info.montant.text"
                    tranform={{ montant: montantSalaireAnnuel?.toLocaleString("fr-FR") }}
                    title="modal.error.info.title"
                    buttonPrimary="modal.error.button"
                />
            </form>
        </FormProvider>
    );
};

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "store/store";
import { SupervisionState } from "store/supervision/types";
import {
  PAGE_TYPE_ERREUR,
  PAGE_TYPE_FORMULAIRE,
  TcVarsEvent,
  TrackingTrackEvent,
} from "./types";

const useTracking = () => {
  const supervisionState: SupervisionState = useSelector<
    State,
    SupervisionState
  >((state) => state.supervision);

  const trackingPageName = window.tc_vars?.page_name;

  const trackPage = (
    pageCategory3: string,
    pageName: string,
    pageType: string,
    formType = "",
    formStep = "",
    complement: Partial<TrackingTrackEvent> = {}
  ) => {
    window.bddfTms?.trackPage({
      page_section: "",
      page_category_1: "epargner",
      page_category_2: "mon-compagnon-retraite",
      page_category_3: pageCategory3,
      page_name: pageName,
      page_type: pageType,
      page_field_2: getSupervisorType(),
      product_category_1: "banque",
      product_category_2: "epargne",
      form_type: formType,
      form_step: formStep,
      page_error_code: "",
      page_error_label: "",
      form_field_1: "",
      form_field_2: "",
      page_field_3: "",
      ...complement,
    });
  };

  /**
   * @param eventName Si isClick, event_name, sinon page_name
   */
  const trackPopin = (
    isClick: boolean,
    pageCategory3: string,
    eventName: string,
    formType = "",
    formStep = "",
    pageType = PAGE_TYPE_FORMULAIRE,
    complement: Partial<TrackingTrackEvent> = {}
  ) => {
    const event = {
      page_name: isClick ? undefined : eventName,
      event_name: isClick ? eventName : undefined,
    };

    window.bddfTms?.trackEvent(null, isClick ? "popin_click" : "popin_page", {
      page_category_1: "epargner",
      page_category_2: "mon-compagnon-retraite",
      page_category_3: pageCategory3,
      ...event,
      page_type: pageType,
      page_field_2: getSupervisorType(),
      product_category_1: "banque",
      product_category_2: "epargne",
      form_type: formType,
      form_step: formStep,
      page_error_code: "",
      page_error_label: "",
      form_field_1: "",
      form_field_2: "",
      page_field_3: "",
      ...complement,
    });
  };

  const trackPopUp = (
    isClick: boolean,
    pageCategory3: string,
    eventName: string,
    formType = "",
    formStep = ""
  ) => {
    trackPopin(isClick, pageCategory3, eventName, formType, formStep);
  };

  const trackClick = (
    eventName: string,
    complement: Partial<TrackingTrackEvent> = {}
  ) => {
    window.bddfTms?.trackEvent(null, "click", {
      event_name: eventName,
      page_field_2: getSupervisorType(),
      // FIXME : Est-ce que ça ne suffirait pas de remettre à vide uniquement dans trackPage ?
      form_field_1: "",
      form_field_2: "",
      page_field_3: "",
      ...complement,
    });
  };

  const trackDownload = (eventName: string) => {
    window.bddfTms?.trackEvent(null, "file_download", {
      event_name: eventName,
      page_field_2: getSupervisorType(),
      form_field_1: "",
      form_field_2: "",
      page_field_3: "",
    });
  };

  const trackTab = (
    eventName: string,
    complement: Partial<TrackingTrackEvent> = {}
  ) => {
    window.bddfTms?.trackEvent(null, "tab_page", {
      event_name: eventName,
      page_field_2: getSupervisorType(),
      form_field_1: "",
      form_field_2: "",
      page_field_3: "",
      ...complement,
    });
  };

  const trackDisclaimer = (eventName: string) => {
    window.bddfTms?.trackEvent(null, "disclaimer_display", {
      event_name: eventName,
      page_field_2: getSupervisorType(),
      form_field_1: "",
      form_field_2: "",
      page_field_3: "",
    });
  };

  const trackDrawer = (eventName: string) => {
    window.bddfTms?.trackEvent(null, "drawer_display", {
      event_name: eventName,
      page_field_2: getSupervisorType(),
      form_field_1: "",
      form_field_2: "",
      page_field_3: "",
    });
  };

  /**
   * A appeler en fermeture de popin. Relance le dernier trackPage
   * TODO: Améliorer pour gérer le cas des doubles popins
   */
  const resetTracking = () => {
    // Récupération des trackPage et trackPopin page par ordre décroissant
    const pages: TcVarsEvent[] = window.tc_vars_array
      ?.filter(
        (ev: TcVarsEvent) =>
          ev.event === "virtual_page" || ev.event === "popin_page"
      )
      .reverse();
    // Si on est dans une popin, on doit remettre à la page
    if (pages && pages.length > 1 && pages[0].event === "popin_page") {
      const currentPage = pages.find(
        (ev) => ev.event === "virtual_page"
      )?.tc_vars;
      if (currentPage) {
        trackPage(
          currentPage.page_category_3,
          currentPage.page_name,
          currentPage.page_type,
          currentPage.form_type,
          currentPage.form_step,
          {
            form_field_1: currentPage.form_field_1,
            form_field_2: currentPage.form_field_2,
          }
        );
      }
    }
  };

  const trackErrorPage = (pageName: string, pageErrorLabel: string) => {
    if (!window.tc_vars) {
      // On n'est pas connecté, on init le tracking
      const loadTrackingScript = () => {
        window.tc_vars = {
          env_work: window.env.REACT_APP_COMMANDERS_ENV,
          env_channel: "website",
          env_market: "particuliers",
          env_is_private: false,
          user_is_logged: false,
          user_is_identified: true,
          page_noload: true,
        };

        try {
          let bddfTmsScript: HTMLScriptElement | null = document.querySelector(
            `script[src="${window.env.REACT_APP_COMMANDERS_TAG_URL}"]`
          );

          if (!bddfTmsScript) {
            bddfTmsScript = document.createElement("script");
            bddfTmsScript.src = window.env.REACT_APP_COMMANDERS_TAG_URL;
            bddfTmsScript.async = true;
            bddfTmsScript.onload = () => {
              trackPage("", pageName, PAGE_TYPE_ERREUR, "", "", {
                page_error_code: "erreur-technique",
                page_error_label: pageErrorLabel,
              });
            };
            document.body.appendChild(bddfTmsScript);
          }
        } catch (e) {
          // Erreur lors du load du tracking;
        }
      };
      loadTrackingScript();
    } else {
      trackPage("", pageName, PAGE_TYPE_ERREUR, "", "", {
        page_error_code: "erreur-technique",
        page_error_label: pageErrorLabel,
      });
    }
  };

  const getSupervisorType = (): string | undefined => {
    if (supervisionState.estActive) {
      if (supervisionState.estModeConsultation) {
        return "consultation";
      }

      return "simulation";
    }

    return undefined;
  };

  return {
    trackingPageName,
    trackPage,
    trackClick,
    trackPopin,
    trackTab,
    resetTracking,
    trackPopUp,
    trackDownload,
    trackDisclaimer,
    trackErrorPage,
    trackDrawer,
  };
};

export { useTracking };

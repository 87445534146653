import { ReactComponent as LinkImage } from "assets/images/link-image-black.svg";
import React, { Fragment, FunctionComponent } from "react";
import { SGContent } from "sg-layout";
import { SGLink } from "sg-link";
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { externalURL } from "website/utils/externalUrl";

const AccessibilitePage: FunctionComponent = () => {
    const criteres = [
        "Nous énumérons ci-dessous tous les critères non conformes. Pour plus de détails, consulter la grille de vérification et le plan pluriannuel d’amélioration continue.",
        "Critère 1.3 : Pour chaque image porteuse d’informations avec une alternative textuelle, cette alternative est-elle pertinente (sauf cas particuliers) ?",
        "Critère 4.1 : Chaque support temporel préenregistré possède-t-il, si nécessaire, une transcription textuelle ou une audiodescription (sauf cas particuliers) ?",
        "Critère 4.3 : Chaque média temporel synchronisé préenregistré possède-t-il, si nécessaire, des sous-titres synchronisés (sauf cas particuliers) ?",
        "Critère 6.1 : Chaque lien est-il explicite (sauf cas particuliers) ?",
        "Critère 7.1 : Chaque script est-il, si nécessaire, compatible avec les technologies d’assistance ?",
        "Critère 7.3 : Chaque script est-il contrôlable par le clavier et par n’importe quel dispositif de pointage (sauf cas particuliers) ?",
        "Critère 7.4 : Pour chaque script qui lance un changement de contexte, l’utilisateur est-il averti ou en contrôle ?",
        "Critère 8.2 : Pour chaque page web, le code source généré est-il valide selon le type de document spécifié (sauf cas particuliers) ?",
        "Critère 8.6 : Pour chaque page Web ayant un titre de page, ce titre est-il pertinent ?",
        "Critère 9.1 : Dans chaque page Web, l’information est-elle structurée par l’utilisation appropriée des titres ?",
        "Critère 10.3 : Sur chaque page Web, l’information demeure-t-elle compréhensible lorsque les feuilles de style sont désactivées ?",
        "Critère 10.4 : Sur chaque page web, le texte reste-t-il lisible lorsque la taille des caractères est augmentée à au moins 200 % (sauf cas particuliers) ?",
        "Critère 10.9 : Dans chaque page Web, l’information ne doit pas être donnée uniquement par la forme, la taille ou la position. Cette règle est-elle respectée ?",
        "Critère 10.11 : Pour chaque page web, le contenu peut-il être présenté sans avoir à utiliser à la fois le défilement vertical pour une fenêtre d’une hauteur de 256px ou le défilement horizontal pour une fenêtre d’une largeur de 320px (sauf cas particuliers) ?",
        "Critère 11.1 : Chaque champ de formulaire a-t-il une étiquette ?",
        "Critère 12.1 : Chaque ensemble de pages possède-t-il au moins deux systèmes de navigation différents (sauf cas particuliers) ?",
        "Critère 12.7 : Dans chaque page Web, y a-t-il un contournement ou un lien d’accès rapide vers la zone de contenu principale (sauf dans des cas particuliers) ?",
        "Critère 12.8 : Dans chaque page Web, l’ordre de tabulation est-il cohérent ?",
    ];

    const pages = [
        "Connectez-vous",
        "Ma retraite",
        "Tableau de bord",
        "J’analyse mon relevé",
        "J’estime les revenus de mon patrimoine",
        "Ma pension - Modificateur",
        "Mon tableau de bord - Épargner pour mes revenus à la retraite",
        "Mon tableau de bord - Mon plan",
        "Mon tableau de bord - J’estime mon économie d’impôts",
        "Comprendre ma retraite",
        "Comprendre ma retraite - De 40 à 50 ans, anticiper et préparer son départ à la retraite",
        "Mes services",
        "Pension",
        "Patrimoine",
    ];

    const getBlock = (text: any, marginTop = "xxs") => (
        <SGBox margin={{ top: marginTop }}>
            {text}
        </SGBox>
    );

    return (
        <SGContent>
            <SGSpace size="xl" direction="vertical">
                <SGTitle key="title" visuallevel={2} level={1} caps>
                    Énoncés d'accessibilité
                </SGTitle>
                <SGSpace key="space-1" direction="vertical">
                    <SGTitle key="title" visuallevel={4} level={2} caps>
                        Déclaration d'accessibilité
                    </SGTitle>
                    <SGBlock key="block-1" component="p" disableautomargin>
                        Société Générale s’engage à rendre son site internet accessible conformément à l’article 47 de la loi n°&nbsp;2005-102 du 11 février
                        2005.
                    </SGBlock>
                    <SGBox key="box">
                        <>
                            <SGBlock component="p" disableautomargin>
                                À cette fin, elle met en œuvre la stratégie et les actions suivantes&nbsp;:
                            </SGBlock>
                            <SGBlock component="ul" disableautomargin>
                                <li>
                                    {getBlock(
                                        <SGLink
                                            href="https://www.societegenerale.com/sites/default/files/documents/2020-10/Sch%C3%A9ma%20Pluriannuel%20de%20Mise%20en%20Accessibilit%C3%A9.pdf"
                                            target="_blank"
                                        >
                                            Plan pluriannuel
                                        </SGLink>
                                    )}
                                </li>
                                <li>
                                    {getBlock(
                                        <SGLink
                                            href="http://www.societegenerale.com/sites/default/files/documents/2020-12/Plan-dAction-Annuel-de-Mise-en-Accessibilite.pdf"
                                            target="_blank"
                                        >
                                            Plan d’action annuel
                                        </SGLink>
                                    )}
                                </li>
                            </SGBlock>
                        </>
                    </SGBox>
                </SGSpace>
                <SGSpace key="space-2" direction="vertical">
                    <SGTitle key="title" visuallevel={4} level={2} caps>
                        État de conformité
                    </SGTitle>
                    <SGBlock key="block" component="p" disableautomargin>
                        Le site internet MonCompagnonRetraite (
                        <SGLink href={externalURL.EXTERNAL_URL_PARTICULIERS} target="_blank">
                            https://moncompagnonretraite.particuliers.sg.fr/
                        </SGLink>
                        ) de My Société Générale est partiellement conforme au Cadre général d’amélioration de l’accessibilité (RGAE), version 4.1, en raison
                        des non-conformités et exemptions énumérées ci-dessous.
                    </SGBlock>
                </SGSpace>
                <SGSpace key="space-3" direction="vertical">
                    <SGTitle key="titre" visuallevel={4} level={2} caps>
                        Tests d’accessibilité
                    </SGTitle>
                    <SGBox key="box">
                        <>
                            <SGBlock component="p" disableautomargin>
                                L’audit de conformité réalisé par Bharath révèle que&nbsp;:
                            </SGBlock>
                            <SGBlock component="ul" disableautomargin>
                                <li>
                                    {getBlock(
                                        "67.27 % des critères RGAA sont remplis. Il s’agit du nombre de critères entièrement remplis sur toutes les pages de l’échantillon."
                                    )}
                                </li>
                                <li>
                                    {getBlock(
                                        <SGText>
                                            Le taux de conformité moyen pour le service en ligne est de 74&nbsp;%.
                                            <SGBlock component="p" disableautomargin>
                                                Il s’agit de la moyenne du score de conformité obtenu sur chaque page de l’échantillon.
                                            </SGBlock>
                                        </SGText>
                                    )}
                                </li>
                                <li>{getBlock(<SGLink href="accessibilite/MonCompagnonRetraite_RGAA4_1.xls">MonCompagnonRetraite_RGAA4_1.xls</SGLink>)}</li>
                            </SGBlock>
                        </>
                    </SGBox>
                </SGSpace>

                <SGSpace key="space-4" direction="vertical">
                    <SGTitle key="title" visuallevel={4} level={2} caps>
                        Contenu non accessible
                    </SGTitle>
                    <SGBlock key="block" component="ol" disableautomargin>
                        <li>
                            <SGBox margin={{ top: "sm", bottom: "sm" }}>
                                <>
                                    <SGText strong> Non-conformités</SGText>
                                    {criteres.map((text: string) => <Fragment key={`sgbox-${text.substring(12)}`}>{getBlock(text, "xs")}</Fragment>)}
                                </>
                            </SGBox>
                        </li>
                        <li>
                            <SGBox margin={{ top: "sm", bottom: "sm" }}>
                                <>
                                    <SGText strong>Dérogations pour charge disproportionnée</SGText>
                                    {getBlock("Aucune dérogation pour charge disproportionnée n’a été prévue.")}
                                </>
                            </SGBox>
                        </li>
                        <li>
                            <SGBox margin={{ top: "sm", bottom: "sm" }}>
                                <>
                                    <SGText strong>
                                        Contenus non soumis à l’obligation d’accessibilité L’article 3 du décret n°&nbsp;2019-768 du 24 juillet 2019 énumère les
                                        contenus exemptés de l’obligation d’accessibilité.
                                    </SGText>
                                    {getBlock("Aucun contenu du site n’a été exempté de l’exigence d’accessibilité.")}
                                </>
                            </SGBox>
                        </li>
                    </SGBlock>
                </SGSpace>
                <SGSpace key="space-5" direction="vertical">
                    <SGTitle key="title" visuallevel={4} level={2} caps>
                        Préparation de la présente déclaration sur l’accessibilité
                    </SGTitle>
                    <SGBlock key="block" component="p" disableautomargin>
                        Cette déclaration a été publiée le 23 Mai 2023.
                    </SGBlock>
                </SGSpace>
                <SGSpace key="space-6" direction="vertical">
                    <SGTitle key="title" visuallevel={4} level={2} caps>
                        Technologies utilisées pour le développement de sites web
                    </SGTitle>
                    <SGBox key="box">
                        <>
                            <SGBlock disableautomargin>
                                Les technologies suivantes sont utilisées sur le site Web&nbsp;:
                            </SGBlock>
                            <SGBlock component="ul" disableautomargin>
                                    <li>{getBlock("HTML 5")}</li>
                                    <li>{getBlock(".CSS")}</li>
                                    <li>{getBlock("JavaScript")}</li>
                                    <li>{getBlock("REACTJS")}</li>
                            </SGBlock>
                        </>
                    </SGBox>
                </SGSpace>
                <SGSpace key="space-7" direction="vertical">
                    <SGTitle key="title" visuallevel={4} level={2} caps>
                        Agents utilisateurs, technologies d’assistance et outils utilisés pour vérifier l’accessibilité
                    </SGTitle>
                    <SGBox key="box-1">
                        <>
                            <SGBlock component="p" disableautomargin>
                                Les tests de pages Web ont été effectués avec les combinaisons suivantes de navigateurs Web et de lecteurs d’écran&nbsp;:
                            </SGBlock>
                            <SGBlock component="ul" disableautomargin>
                                <li>
                                    {getBlock(
                                        <SGText>
                                            Agent utilisateur
                                            <SGBlock component="ul" disableautomargin>
                                                <li>Chrome 92.0.4515.107</li>
                                            </SGBlock>
                                        </SGText>
                                    )}
                                </li>
                                <li>
                                    {getBlock(
                                        <SGText>
                                            Technologie d’assistance
                                            <SGBlock component="ul" disableautomargin>
                                                <li>NVDA 2018.3.2</li>
                                            </SGBlock>
                                        </SGText>
                                    )}
                                </li>
                            </SGBlock>
                        </>
                    </SGBox>
                    <SGBlock key="block-3" component="p" disableautomargin>
                        <SGText strong>Outils utilisés</SGText>
                    </SGBlock>
                    <SGBox key="box-2">
                        <>
                            <SGBlock component="p" disableautomargin>
                                Les outils suivants ont été utilisés dans le cadre de l’évaluation&nbsp;:
                            </SGBlock>
                            <SGBlock component="ul" disableautomargin>
                                    <li>{getBlock("Extension Axe publiée par Deque pour Chrome.")}</li>
                                    <li>{getBlock("Kontrast - Vérificateur de contraste WCAG (Chrome)")}</li>
                                    <li>
                                        {getBlock(<SGText>Chris Pederick Web Developer Browser Extension&nbsp;: https://chrispederick.com/work/web-developer/</SGText>)}
                                    </li>
                            </SGBlock>
                        </>
                    </SGBox>
                    <SGBox key="box-3">
                        <>
                            <SGBlock component="p" disableautomargin>
                                <SGText strong>Pages du site soumises à vérification de conformité</SGText>
                            </SGBlock>
                            <SGBlock component="ul" disableautomargin>
                                <li>
                                    Échantillon structuré
                                    <SGBlock component="ul" disableautomargin>
                                        {pages.map((text: string) => (
                                            <li key={`li-${text.substring(-10)}`}>{getBlock(text, "xs")}</li>
                                        ))}
                                    </SGBlock>
                                </li>
                            </SGBlock>
                        </>
                    </SGBox>
                </SGSpace>
                <SGSpace key="space-8" direction="vertical">
                    <SGTitle key="title" visuallevel={4} level={2} caps>
                        Commentaires et contact
                    </SGTitle>
                    <SGBox key="box">
                        <>
                            <SGBlock component="p" disableautomargin>
                                Si vous ne parvenez pas à accéder à un contenu ou à un service, vous pouvez contacter le responsable de My Société Générale pour être
                                orienté vers une alternative accessible ou pour obtenir le contenu sous une autre forme.
                            </SGBlock>
                            <SGBlock component="ul" disableautomargin>
                                <li>
                                    {getBlock(
                                        <SGText>
                                            Envoyer un message&nbsp;: voir page «&nbsp;
                                            <SGLink href={externalURL.EXTERNAL_URL_CONTACT} target="_blank">
                                                contacts
                                            </SGLink>
                                            &nbsp;»
                                        </SGText>
                                    )}
                                </li>
                                <li>{getBlock("Contact : COMM/EDE/PROTours Société Générale189 rue d’Aubervilliers 75886 Paris cedex 18")}</li>
                            </SGBlock>
                        </>
                    </SGBox>
                </SGSpace>
                <SGSpace key="space-9" direction="vertical">
                    <SGTitle key="title" visuallevel={4} level={2} caps>
                        Remèdes
                    </SGTitle>
                    <SGBlock key="block-1" component="p" disableautomargin>
                        Cette procédure doit être utilisée dans le cas suivant.
                    </SGBlock>
                    <SGBox key="box">
                        <>
                            <SGBlock component="p" disableautomargin>
                                Vous avez signalé au mainteneur du site internet un défaut d’accessibilité qui vous empêche d’accéder au contenu ou aux services du
                                portail et vous n’avez pas reçu de réponse satisfaisante.
                            </SGBlock>
                            <SGBlock component="ul" disableautomargin>
                                <li>
                                    {getBlock(
                                        <SGText>
                                            Écrivez un message à&nbsp;
                                            <SGLink href={externalURL.EXTERNAL_URL_DEFENSEURDESDROITS} target="_blank" icon={<LinkImage />} iconside="right">
                                                l’avocat
                                            </SGLink>
                                        </SGText>
                                    )}
                                </li>
                                <li>
                                    {getBlock(
                                        <SGText>
                                            Communiquez avec le délégué de l’intervenant de votre région&nbsp;
                                            <SGLink
                                                href={externalURL.EXTERNAL_URL_DEFENSEURDESDROITS_DELEGUES}
                                                target="_blank"
                                                icon={<LinkImage />}
                                                iconside="right"
                                            >
                                                délégué du Défenseur des droits dans votre région
                                            </SGLink>
                                        </SGText>
                                    )}
                                </li>
                                <li>
                                    {getBlock(
                                        "Envoyer un mail (gratuit, ne pas mettre de tampon) Défenseur des droits Réponse gratuite 71120 75342 Paris CEDEX 07"
                                    )}
                                </li>
                            </SGBlock>
                        </>
                    </SGBox>
                </SGSpace>
            </SGSpace>
        </SGContent>
    );
};

export { AccessibilitePage };

import { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { SGAvenirStyledFinancement } from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGText } from "sg-typo";
import { SimulateurEconomiesImpotsState } from "store/simulateurEconomiesImpots/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useSimulateurEconomiesImpotsState } from "website/components/hooks/useSimulateurEconomiesImpotsState";
import { SIMULATEUR_ECONOMIES_IMPOTS } from "website/utils/privateRoutes";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";

export const AccesSimulateurEconomieImpot: FunctionComponent = () => {
    const history = useHistory();
    const intl = useIntl();
    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    const { trackClick } = useTracking();

    const simulateurEconomiesImpotsState: SimulateurEconomiesImpotsState = useSimulateurEconomiesImpotsState();

    const handleOnClickSEILink = () => {
        // Tracking [Clic] Clic sur le lien PER estimation d'économie d'impôts - MODIFICATION
        trackClick("clic-sur-per-mon-economie-d-impot");
        history.push(SIMULATEUR_ECONOMIES_IMPOTS);
    };

    return (
        <>
            <TuileCliquable
                disableautomargin={!isPhone}
                icone={<SGAvenirStyledFinancement />}
                iconeTaille="s"
                titre={intl.formatMessage({
                        id: "dashboard.sei.title",
                    })}
                texte={intl.formatMessage(
                    {
                        id:
                            simulateurEconomiesImpotsState.savedParameters.payment === undefined
                                ? "dashboard.sei.new"
                                : "dashboard.sei.modify",
                    },
                    {
                        economieImpot: simulateurEconomiesImpotsState.taxEconomy.taxSaving.toLocaleString("fr-FR"),
                        b: (word: string) => <SGText weight="700">{word}</SGText>,
                    }
                )}
                onClick={handleOnClickSEILink}
            />
        </>
    );
};

import {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText } from "sg-typo";
import { getAssetsIncomes } from "store/assetIncomes/actions";
import {
  AgeTypeEnum,
  AssetIncomesState,
  Incomes,
  hasFetchedAssets,
} from "store/assetIncomes/types";
import {
  getAssetsWithoutLoans,
  updateAssetsForRetirement,
} from "store/assets/actions";
import { AssetState, AssetWithValuation, actifsNonProjetables } from "store/assets/types";
import { getCleanName } from "store/assets/utils";
import { updatePrefs } from "store/dashboard/actions";
import { MONTHS, User, hasFetchedDashboard } from "store/dashboard/types";
import { previousStep } from "store/parcoursRevenusComplementaires/action";
import { convertAgeTypeToWealthProfile } from "store/savingPath/types";
import { State } from "store/store";
import { BoutonSupervision } from "website/components/atoms/BoutonSupervision/BoutonSupervision";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { useSelectedRetirementAge } from "website/components/hooks/dashboard/useSelectedRetirementAge";
import {
  PAGE_TYPE_FORMULAIRE,
  TRACKING_CLICK_HYPOTHESES,
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useAssetPercentages } from "website/components/hooks/useAssetPercentages";
import {
  trackingEvent,
  useTrackingEvent,
} from "website/components/hooks/useTrackingEvent";
import { AssetCard } from "website/components/molecules/AssetCard/AssetCard";
import { Faq } from "website/components/molecules/Faq/Faq";
import {
  HypothesisAnchor,
  HypothesisModal,
} from "website/components/molecules/HypothesisModal/HypothesisModal";
import { EditorContext } from "website/components/providers/EditorProvider";
import { roundDown10 } from "website/utils/formatting/numberFormatter";
import { DASHBOARD } from "website/utils/privateRoutes";
import "./ParcoursRevenusComplementairesEstimate.scss";

const ParcoursRevenusComplementairesEstimate: FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const saveEvent = useTrackingEvent();
  const intl = useIntl();
  const { trackClick, trackPage } = useTracking();

  const ageTypes: string[] = Object.values(AgeTypeEnum);
  const assetState: AssetState = useSelector<State, AssetState>(
    (state) => state.assets
  );
  const assetIncomesState: AssetIncomesState = useSelector<
    State,
    AssetIncomesState
  >((state) => state.assetIncomes);
  const dashboardState = useDashboardState();
  const { agesTries } = useSelectedRetirementAge();

  const [initAgeIncomes, setInitAgeIncomes] = useState(false);
  const [filteredAssets, setFilteredAssets] = useState<AssetWithValuation[]>(
    []
  );
  const [incomes, setIncomes] = useState<Incomes[]>([]);
  const [checkedAssets, setCheckedAssets] = useState<number[]>();
  const [showHypothesisModal, setShowHypothesisModal] = useState(false);
  const assetPercentages = useAssetPercentages();
  const { percentages, setPercentages } = useContext(EditorContext);
  const [savingAssets, setSavingAssets] = useState<AssetWithValuation[]>();

  const faqBodyList = ["calculRevenu", "rendement", "simulation", "afiner"];

  const { assets } = assetState;
  const { assetIncomes } = assetIncomesState;

  const handlePrevious = () => {
    trackClick("estimer-mes-revenus::clic-sur-retour");
    dispatch(previousStep());
  };

  useEffect(() => {
    trackPage(
      "patrimoine",
      "estimer-mes-revenus",
      PAGE_TYPE_FORMULAIRE,
      "simulation",
      "2"
    );
    if (!assetState.hasFetched || assets.length === 0) {
      dispatch(getAssetsWithoutLoans());
    }
    dispatch(getAssetsIncomes());
  }, []);

  useEffect(() => {
    setFilteredAssets(
      assets.filter(
        (asset) => actifsNonProjetables.indexOf(asset?.assetType) === -1
      )
    );
  }, [assets]);

  // Initialisation des pourcentages suite à useAssetPercentages()
  useEffect(() => {
    setPercentages(assetPercentages);
  }, [assetPercentages]);

  // On calcul de nouveau les revenus lors d'un changement de pourcentage
  useEffect(() => {
    // Seulement si les assets cochés sont déjà identifiés (donc pas lors de l'initialisation)
    if (checkedAssets) {
      calculateIncomes(checkedAssets);
    }
  }, [percentages]);

  useEffect(() => {
    // Au chargement des données du dashboard, on crée la liste des incomes (avec les âges)
    if (
      hasFetchedDashboard(dashboardState) &&
      agesTries &&
      incomes.length === 0
    ) {
      const newIncomes: Incomes[] = [];
      agesTries.forEach((ageDashboard) => {
        newIncomes.push({
          age: ageDashboard.retirementAgeYear,
          income: 0,
          ageType: convertAgeTypeToWealthProfile(ageDashboard.type),
          date: `${MONTHS[
            ageDashboard.retirementMonth - 1
          ]?.toLocaleLowerCase()} ${ageDashboard.retirementYear?.toString()}`,
          months: ageDashboard.retirementAgeMonth,
          checkedAssets: 0,
        });
      });
      // On tri les ages
      newIncomes.sort(
        (a, b) => a.age - b.age || (a.months || 0) - (b.months || 0)
      );
      // Alimentation des revenus par âge
      setIncomes(newIncomes);
      setInitAgeIncomes(true);
    }
  }, [dashboardState, agesTries]);

  // Appelé une fois au début, lorsque les assets ont été construits avec les âges
  useEffect(() => {
    if (
      incomes.length !== 0 &&
      hasFetchedAssets(assetIncomesState) &&
      ageTypes.filter((age: string) => assetIncomes[age]).length > 0 &&
      initAgeIncomes
    ) {
      setInitAgeIncomes(false);
      // Identification des assets cochés
      const newCheckedAssets: number[] = [];
      filteredAssets.forEach((asset) => {
        if (asset?.id && asset.percentageUsedForRetirement > 0) {
          newCheckedAssets.push(asset.id);
        }
      });
      setCheckedAssets(newCheckedAssets);
      calculateIncomes(newCheckedAssets);
    }
  }, [assetIncomesState, incomes]);

  useEffect(() => {
    // Si on retrouve les mêmes assets que ceux à sauvegarder, c'est qu'on a fini
    // Les assets ici, correspondent au filteredAssets envoyés à wealth
    if (
      dashboardState.dashboard?.firstPatrimonyPathDate &&
      savingAssets &&
      assetState.hasFetched &&
      assets.length === savingAssets.length &&
      assets.filter((a) => savingAssets.find((sa) => a.id === sa.id)).length ===
        savingAssets.length
    ) {
      history.push(DASHBOARD);
    }
  }, [
    savingAssets,
    assetState,
    dashboardState.dashboard?.firstPatrimonyPathDate,
  ]);

  const handleChecked = (checked: boolean, assetId: number) => {
    const asset = assets.find((a) => a.id === assetId);
    if (asset) {
      const assetName = getCleanName(asset.assetName);
      trackClick(
        `estimer-mes-revenus::clic-sur-${
          checked ? "cocher" : "decocher"
        }-${assetName}`
      );
      checkAsset(checked, assetId);

      // Si une asset est cochée, son pourcentage de revenu est défini à 100%, sinon 0
      setPercentages({ ...percentages, [assetId]: checked ? 100 : 0 });
    }
  };

  /**
   * Ajoute ou supprime l'asset qui a été coché ou décoché
   * Non utilisé lors de l'initialisation, car correspond à une action simple (pas multiple)
   */
  function checkAsset(checked: boolean, assetId: number) {
    let newCheckedAssets = checkedAssets ?? [];
    let newAssets = [];
    if (checked) {
      newCheckedAssets?.push(assetId);
      newAssets = filteredAssets.map((asset) =>
        asset.id === assetId
          ? { ...asset, percentageUsedForRetirement: 100 }
          : asset
      );
    } else {
      newCheckedAssets = newCheckedAssets?.filter((asset) => asset !== assetId);
      newAssets = filteredAssets.map((asset) =>
        asset.id === assetId
          ? { ...asset, percentageUsedForRetirement: 0 }
          : asset
      );
    }
    setFilteredAssets(newAssets);
    setCheckedAssets(newCheckedAssets);
    calculateIncomes(newCheckedAssets);
  }

  // Realise la somme des montants des assets cochés pour chaque ages
  function calculateIncomes(newCheckedAssets: number[]) {
    // Pour retirer les âges filtrés
    const agesWealth = agesTries?.map((age) => convertAgeTypeToWealthProfile(age.type)) || [];
    // On boucle sur les différents types d'ages
    const newIncomes: Incomes[] = ageTypes
      .filter(
        (ageType) =>
          hasFetchedAssets(assetIncomesState) && assetIncomes[ageType] && agesWealth.includes(ageType)
      )
      .map((ageType) => {
        let assetIncomeValue = 0;
        // On boucle sur la liste des assets cochés pour calculer le montant de l'income correspondant à l'age
        // et en fonction de la part attribuée en pourcentage (assetPercentages)
        newCheckedAssets?.forEach((asset) => {
          if (assetIncomes[ageType][asset]) {
            assetIncomeValue +=
              percentages && percentages[asset]
                ? (assetIncomes[ageType][asset] * percentages[asset]) / 100
                : assetIncomes[ageType][asset];
          }
        });

        // On cherche l'income correspondant à cet age
        const incomeByAge = incomes.find(
          (income) => income.ageType === ageType
        );

        return {
          income: roundDown10(assetIncomeValue),
          date: incomeByAge?.date || "",
          age: incomeByAge?.age || 0,
          ageType,
          months: incomeByAge?.months,
          checkedAssets: newCheckedAssets.length,
        };
      });

    // On tri les ages
    newIncomes.sort(
      (a, b) => a.age - b.age || (a.months || 0) - (b.months || 0)
    );
    setIncomes(newIncomes);
  }

  const handleUpdateRetirement = () => {
    trackClick(
      "estimer-mes-revenus::clic-sur-mettre-a-jour-mon-bilan-retraite"
    );
    saveEvent(trackingEvent.PATRIMOINE_MAJ);

    // Sauvegarde les pourcentages des assets différents
    const filteredAssetsWithPercentages = filteredAssets.map((asset) => ({
      ...asset,
      percentageUsedForRetirement: (asset.id && percentages[asset.id]) || 0,
    }));
    dispatch(updateAssetsForRetirement(filteredAssetsWithPercentages));

    // save firstPatrimonyPathDate
    if (dashboardState.dashboard.firstPatrimonyPathDate === undefined) {
      const req: User = {
        firstPatrimonyPathDate: new Date(),
      };
      dispatch(updatePrefs(req));
    }

    // Décalage de la redirection au dashboard pour attendre la fin de la sauvegarde
    setSavingAssets(filteredAssetsWithPercentages);
  };

  function onClickHypothesisModal() {
    trackClick(`estimer-mes-revenus::${TRACKING_CLICK_HYPOTHESES}`);
    setShowHypothesisModal(true);
  }

  return (
    <SGGridRow gutter={[0, 43]}>
      <SGGridCol span={12}>
        <SGGridRow gutter={[0, 21]}>
          <SGGridCol span={12}>
            <AssetCard
              assets={filteredAssets}
              incomes={incomes}
              addOrRemoveAsset={handleChecked}
              initAgeIncomes={initAgeIncomes}
            />
          </SGGridCol>
          <SGGridCol span={12}>
            <SGMediaQuery minwidth="xs" maxwidth="xs">
              <SGText size="m" style={{ color: "#636C6E" }}>
                <FormattedMessage
                  id="parcoursRevenusComplementaires.mention-mob"
                  values={{
                    hypothese: (word: string) => (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={onClickHypothesisModal}
                      >
                        {word}
                      </span>
                    ),
                  }}
                />
              </SGText>
            </SGMediaQuery>
            <SGMediaQuery minwidth="sm">
              <SGText size="s" style={{ color: "#636C6E" }}>
                <FormattedMessage
                  id="parcoursRevenusComplementaires.mention"
                  values={{
                    hypothese: (word: string) => (
                      <span
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={onClickHypothesisModal}
                      >
                        {word}
                      </span>
                    ),
                  }}
                />
              </SGText>
            </SGMediaQuery>
          </SGGridCol>
        </SGGridRow>
      </SGGridCol>
      <SGGridCol span={12}>
        <SGMediaQuery minwidth="xs" maxwidth="xs">
          <SGButtonGroup align="center" layout="column">
            <BoutonSupervision
              onClick={handleUpdateRetirement}
              cypressName="parcours-revenus-complementaires-submit"
            >
              {intl.formatMessage({
                id: "parcoursRevenusComplementaires.step.estimer.maj.bilan",
              })}
            </BoutonSupervision>
            <SGButton onClick={handlePrevious} type="link">
              {intl.formatMessage({ id: "common.previous" })}
            </SGButton>
          </SGButtonGroup>
        </SGMediaQuery>
        <SGMediaQuery minwidth="sm">
          <SGButtonGroup align="opposite" responsive={false}>
            <SGButton onClick={handlePrevious} type="link">
              {intl.formatMessage({ id: "common.previous" })}
            </SGButton>
            <BoutonSupervision onClick={handleUpdateRetirement}>
              {intl.formatMessage({
                id: "parcoursRevenusComplementaires.step.estimer.maj.bilan",
              })}
            </BoutonSupervision>
          </SGButtonGroup>
        </SGMediaQuery>
      </SGGridCol>
      <SGGridCol span={12}>
        <SGBox margin={{ left: "xl", right: "xl", bottom: "xl" }}>
          <Faq
            bodyFaq={faqBodyList}
            setShowHypothesisModal={setShowHypothesisModal}
            openDefaultKeys={[]}
            trackKeyParcours="estimer-mes-revenus"
          />
        </SGBox>
      </SGGridCol>
      {showHypothesisModal && (
        <HypothesisModal
          setVisible={setShowHypothesisModal}
          visible={showHypothesisModal}
          anchor={HypothesisAnchor.PATRIMOINE}
          closeFirstGroup
        />
      )}
    </SGGridRow>
  );
};

export { ParcoursRevenusComplementairesEstimate };

import React, { FunctionComponent, ReactNode } from "react";
import { useSelector } from "react-redux";
import { SGButton } from "sg-button";
import { State } from "store/store";

interface BoutonSGAgendaProps {
  type?: string;
  onClick?: () => void;
  icon?: ReactNode;
  iconposition?: string;
  disabled?: boolean;
  disableautomargin?: boolean;
  ariaLabel?: string;
}

/**
 * Bouton gérant le débranchement vers SGAgenda.
 * Il sera automatiquement désactivé si l'utilisateur est en mode supervision.
 */
export const BoutonSGAgenda: FunctionComponent<BoutonSGAgendaProps> = (
  props: React.PropsWithChildren<BoutonSGAgendaProps>
) => {
  const { type, onClick, icon, iconposition, disabled, disableautomargin, children, ariaLabel } = props;

  const estSupervisionActive: boolean = useSelector<State, boolean>(
    (state) => state.supervision.estActive
  );

  return (
    <SGButton
      type={type}
      onClick={onClick}
      disabled={estSupervisionActive || disabled}
      icon={icon}
      iconposition={iconposition}
      disableautomargin={disableautomargin}
      ariaLabel={ariaLabel}
    >
      {children}
    </SGButton>
  );
};

import sapiendoImg from "assets/images/sapiendo-baseline.svg";
import { FunctionComponent, SyntheticEvent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGLink } from "sg-link";
import { SGMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGScroll } from "sg-scroll";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { updatePrefs } from "store/dashboard/actions";
import { User } from "store/dashboard/types";
import { Image } from "website/components/atoms/Image/Image";
import { useDashboardState } from "website/components/hooks/dashboard/useDashboardState";
import { TRACKING_CLICK_HYPOTHESES } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import { trackingEvent, useTrackingEvent } from "website/components/hooks/useTrackingEvent";
import { HypothesisAnchor, HypothesisModal } from "../HypothesisModal/HypothesisModal";
import "./LegalNoticesModal.scss";

interface LegalNoticesModalProps {
    visible: boolean;
    setVisible: (showLegalNoticeModal: boolean) => void;
}
export const LegalNoticesModal: FunctionComponent<LegalNoticesModalProps> = (props: LegalNoticesModalProps) => {
    const { visible, setVisible } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const saveEvent = useTrackingEvent();
    const openLink = useOpenLink();
    const dashboardState = useDashboardState();
    const { trackPopin, resetTracking } = useTracking();

    const [showHypothesisModal, setShowHypothesisModal] = useState(false);

    const acceptLegalNotices = () => {
        // save legal notices acceptance date
        const req: User = {
            legalNoticesAcceptanceDate: new Date(),
        };

        trackPopin(true, "parcours-onboarding", "mentions-legales::clic-sur-jai-compris", "inscription", "5");
        saveEvent(trackingEvent.MENTIONS_LEGALES);

        dispatch(updatePrefs(req));
    };

    useEffect(() => {
        if (visible) {
            trackPopin(false, "parcours-onboarding", "mentions-legales", "inscription", "5");
        }
    }, [visible]);

    // Fermeture de la popin et ràz du tracking une fois les mentions acceptée côté back
    useEffect(() => {
        if (visible && dashboardState.dashboard?.legalNoticesAcceptanceDate) {
            setVisible(false);
            resetTracking();
        }
    }, [dashboardState.dashboard?.legalNoticesAcceptanceDate]);

    const onClickHypothesisModal = () => {
        trackPopin(true, "parcours-onboarding", `mentions-legales::${TRACKING_CLICK_HYPOTHESES}`, "inscription", "5");
        setShowHypothesisModal(true);
    };

    return (
        <SGModal className="legal-notices-modal" visible={visible} closable={false}>
            <SGGridRow gutter={[0, 10]}>
                <SGGridCol span={12} textalign="left">
                    <SGTitle caps visuallevel={2}>
                        {intl.formatMessage({ id: `legalNotices.modal.title` })}
                    </SGTitle>
                </SGGridCol>
                <SGGridCol>
                    <SGScroll disableautomargin>
                        <SGGridRow
                            gutter={[
                                0,
                                15,
                            ]}
                        >
                            <SGGridCol span={12}>
                                <SGText className="legal-notices-modal__black-text">
                                    {intl.formatMessage(
                                        { id: `legalNotices.modal.paragraph1` },
                                        { b: (word: string) => <span style={{ fontWeight: 600 }}>{word}</span> }
                                    )}
                                </SGText>
                            </SGGridCol>
                            <SGMediaQuery minwidth="md">
                                <SGGridCol span={12}>
                                    <SGGridRow gutter={[1]}>
                                        <SGGridCol span={4} align="center">
                                            <Image src={sapiendoImg} alt="logo-sapiendo" className="legal-notices-modal__img" />
                                        </SGGridCol>
                                        <SGGridCol span={8}>
                                            <SGText className="legal-notices-modal__black-text">
                                                {intl.formatMessage(
                                                    { id: `legalNotices.modal.paragraph2` },
                                                    { b: (word: string) => <span style={{ fontWeight: 600 }}>{word}</span> }
                                                )}
                                            </SGText>
                                        </SGGridCol>
                                    </SGGridRow>
                                </SGGridCol>
                            </SGMediaQuery>
                            <SGMediaQuery minwidth={0} maxwidth="sm">
                                <SGGridCol span={12} textalign="center">
                                    <Image src={sapiendoImg} alt="logo-sapiendo" className="legal-notices-modal__img" />
                                </SGGridCol>
                                <SGGridCol span={12} align="start">
                                    <SGText className="legal-notices-modal__black-text">
                                        {intl.formatMessage(
                                            { id: `legalNotices.modal.paragraph2` },
                                            { b: (word: string) => <span style={{ fontWeight: 600 }}>{word}</span> }
                                        )}
                                    </SGText>
                                </SGGridCol>
                            </SGMediaQuery>
                            <SGGridCol span={12}>
                                <SGText className="legal-notices-modal__black-text">
                                    {intl.formatMessage(
                                        { id: `legalNotices.modal.paragraph3` },
                                        { b: (word: string) => <span style={{ fontWeight: 600 }}>{word}</span> }
                                    )}
                                </SGText>
                            </SGGridCol>
                            <SGGridCol span={12}>
                                <SGText className="legal-notices-modal__black-text">
                                    {intl.formatMessage(
                                        { id: `legalNotices.modal.paragraph4` }
                                    )}
                                </SGText>
                            </SGGridCol>
                            <SGGridCol span={12}>
                                <SGText className="legal-notices-modal__black-text">
                                    {intl.formatMessage(
                                        { id: `legalNotices.modal.paragraph5` },
                                        {
                                            sga: (word: string) => {
                                                const link = window.env.REACT_APP_BDDF_URL;

                                                return (
                                                    <SGLink
                                                        key="lien-sga"
                                                        style={{ fontWeight: 400, color: "#0072b9" }}
                                                        href={link}
                                                        onClick={(event: SyntheticEvent) => openLink(link, event)}
                                                    >
                                                        {word}
                                                    </SGLink>
                                                );
                                            },
                                            pers: (word: string) => {
                                                const link = window.env.REACT_APP_BDDF_URL + intl.formatMessage({ id: "link.rgpd" });

                                                return (
                                                    <SGLink
                                                        key="lien-pers"
                                                        style={{ fontWeight: 400, margin: 0, color: "#0072b9" }}
                                                        href={link}
                                                        onClick={(event: SyntheticEvent) => openLink(link, event)}
                                                    >
                                                        {word}
                                                    </SGLink>
                                                );
                                            },
                                        }
                                    )}
                                </SGText>
                            </SGGridCol>
                            <SGGridCol span={12}>
                                <SGMediaQuery minwidth="md">
                                    <SGGridRow justify="center">
                                        <SGGridCol span={9}>
                                            <SGTitle textalign="center" level={4}>
                                                {intl.formatMessage({ id: `legalNotices.modal.subtitle` })}
                                            </SGTitle>
                                        </SGGridCol>
                                    </SGGridRow>
                                </SGMediaQuery>
                                <SGMediaQuery minwidth={0} maxwidth="sm">
                                    <SGGridRow align="middle">
                                        <SGGridCol span={12} style={{ marginTop: 10 }}>
                                            <SGTitle textalign="center" level={4}>
                                                {intl.formatMessage({ id: `legalNotices.modal.subtitle` })}
                                            </SGTitle>
                                        </SGGridCol>
                                    </SGGridRow>
                                </SGMediaQuery>
                            </SGGridCol>
                        </SGGridRow>
                    </SGScroll>
                </SGGridCol>
                <SGGridCol span={12}>
                    <SGBox margin={{ left: "md", right: "md" }}>
                        <SGButtonGroup layout="column" align="center" disableautomargin>
                            <SGButton onClick={acceptLegalNotices} size="sm">
                                {intl.formatMessage({ id: `legalNotices.modal.button` })}
                            </SGButton>
                            <SGButton type="link" onClick={onClickHypothesisModal}>
                                <SGBox textalign="center">
                                    {intl.formatMessage({ id: `legalNotices.modal.details` })}
                                </SGBox>
                            </SGButton>
                        </SGButtonGroup>
                    </SGBox>
                </SGGridCol>
            </SGGridRow>
            {showHypothesisModal && (
                <HypothesisModal setVisible={setShowHypothesisModal} visible={showHypothesisModal} anchor={HypothesisAnchor.FLASH} closeSecondGroup />
            )}
        </SGModal>
    );
};

import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { SGDivider } from "sg-divider";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGHeader, SGLayout } from "sg-layout";
import { SGLogo } from "sg-logo";
import { SGBox } from "sg-space";
import {
  MenuItem,
  getIdTracking,
  useMenu,
} from "website/components/hooks/useMenu";
import { MenuDesktop } from "./MenuDesktop";
import "./HeaderDesktop.scss";
import { useTracking } from "website/components/hooks/tracking/useTracking";

const HeaderDesktop: FunctionComponent = () => {
  const history = useHistory();
  const menuItems = useMenu();
  const { pathname, search } = useLocation();
  const { trackClick } = useTracking();

  const [menuCourant, setMenuCourant] = useState<MenuItem>();
  const [sousMenuCourant, setSousMenuCourant] = useState<MenuItem>();

  useEffect(() => {
    // Permet de mettre à jour le menu quand le path change
    if (pathname !== menuCourant?.path && pathname !== sousMenuCourant?.path) {
      const menu = menuItems.find(
        (menuItem: MenuItem) =>
          menuItem.path === `${pathname}${search}` ||
          menuItem.sousMenus?.find(
            (sousMenuItem: MenuItem) =>
              sousMenuItem.path === `${pathname}${search}`
          )
      );

      setMenuCourant(menu);
      setSousMenuCourant(
        menu?.sousMenus?.find(
          (sousMenuItem: MenuItem) =>
            sousMenuItem.path === `${pathname}${search}`
        )
      );
    }
  }, [pathname, search]);

  const onChangeMenu = (key: string): void => {
    const newMenuCourant = menuItems.find((menuItem) => menuItem.key === key);
    setMenuCourant(newMenuCourant);
    if (newMenuCourant) {
      trackClick(`clic-sur-${getIdTracking(newMenuCourant.key)}`);
      if (newMenuCourant.sousMenus === undefined && newMenuCourant.path && newMenuCourant.path !== pathname) {
        history.push(newMenuCourant.path);
        setSousMenuCourant(undefined);
      }
    }
  };

  const onChangeSousMenu = (key: string): void => {
    if (menuCourant && menuCourant.sousMenus) {
      const newSousMenuCourant = menuCourant.sousMenus.find(
        (menuItem) => menuItem.key === key
      );

      setSousMenuCourant(newSousMenuCourant);
      if (newSousMenuCourant?.path) {
        trackClick(
          `${getIdTracking(menuCourant.key)}::clic-sur-${getIdTracking(
            newSousMenuCourant.key
          )}`
        );
        history.push(newSousMenuCourant.path);
      }
    }
  };

  return (
    <div className="headerAssu">
      <SGHeader disableautomargin>
        <SGBox padding={{ top: "sm" }}>
          <>
            <SGGridRow gutter={[0, 0]}>
              <SGGridCol flex="0 0 0%" textalign="right" align="center">
                <SGBox margin={{ left: "lg", right: "xl" }}>
                  <SGLogo baseline width={137} />
                </SGBox>
              </SGGridCol>
              <SGGridCol flex="1 0 0%" align="center" textalign="left">
                <MenuDesktop
                  menuItems={menuItems.filter(
                    (menuItem) => menuItem.icone === undefined
                  )}
                  onChangeMenu={onChangeMenu}
                  menuCourant={menuCourant}
                />
              </SGGridCol>
              <SGGridCol flex="0 0 0%">
                <SGBox margin={{ right: "lg" }}>
                  <MenuDesktop
                    key={`${
                      menuItems.length !== 1 ? "allmenus" : "Logoutmenu"
                    }`}
                    menuItems={menuItems.filter(
                      (menuItem) => menuItem.icone !== undefined
                    )}
                    onChangeMenu={onChangeMenu}
                    menuCourant={menuCourant}
                    sizeText="xs"
                  />
                </SGBox>
              </SGGridCol>
            </SGGridRow>

            {menuCourant?.sousMenus && (
              <>
                <SGDivider disableautomargin />

                <SGLayout background="light">
                  <SGBox
                    margin={{ left: "xxl" }}
                    padding={{ left: "xxl", top: "xs", bottom: "xs" }}
                  >
                    <MenuDesktop
                      menuItems={menuCourant.sousMenus}
                      onChangeMenu={onChangeSousMenu}
                      menuCourant={sousMenuCourant}
                      weightText="400"
                      sizeText="s"
                    />
                  </SGBox>
                </SGLayout>
              </>
            )}
            <SGDivider disableautomargin />
          </>
        </SGBox>
      </SGHeader>
    </div>
  );
};

export { HeaderDesktop };

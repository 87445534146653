import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { useMediaQuery } from "sg-media-query";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { ImportState } from "store/import/types";
import { CustodyType, FamilyStatus, MaritalStatus, RetirementCategory } from "store/members/types";
import { CategorieActiveChoix } from "store/simulateurAvantApres/types";
import { State } from "store/store";
import { previousStepAction } from "store/tunnel/actions";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { useFamily } from "website/components/hooks/useFamily";
import { ChildrenCounterAssu } from "website/components/molecules/ChildrenCounterAssu/ChildrenCounterAssu";
import { InfoTooltipAssu } from "website/components/molecules/InfoTooltipAssu/InfoTooltipAssu";
import { DetailTabContext } from "website/components/providers/DetailTabProvider";
import "./FamilySituation.scss";

interface FamilySituationProps {
    showReturn?: boolean;
    onSubmit: () => void;
    numberChild: number;
    setNumberChild: (value: any) => void;
    retirementType?: RetirementCategory;
    setRetirementType: (value: RetirementCategory) => void;
    categorieActive: CategorieActiveChoix;
    setCategorieActive: (value: CategorieActiveChoix) => void;
    setDateOfBirth: (value: Date) => void;
    setMaritalStatus: (value: MaritalStatus) => void;
}

export const FamilySituation: FunctionComponent<FamilySituationProps> = (props: FamilySituationProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {
        showReturn,
        onSubmit,
        numberChild,
        setNumberChild,
        retirementType,
        setRetirementType,
        categorieActive,
        setCategorieActive,
        setDateOfBirth,
        setMaritalStatus,
    } = props;

    const max = 9;

    const [dateOfBirthToShow, setDateOfBirthToShow] = useState<string>();
    const importData: ImportState = useSelector<State, ImportState>((state) => state.importData);
    const family = useFamily();
    const { setShowDetailFonctionnaire } = useContext(DetailTabContext);
    const retirementTypeValues: RetirementCategory[] = Object.values(RetirementCategory);
    const optionCategorieActiveChoix: CategorieActiveChoix[] = Object.values(CategorieActiveChoix);

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });
    const isVisible = useMediaQuery({ maxwidth: "xl"});

    const methods = useForm({
        criteriaMode: "all",
        mode: "onSubmit",
        reValidateMode: "onChange",
    });
    const { handleSubmit, formState } = methods;

    // Permet de revalider la valeur de l'input childrenCounter suite à l'utilisation d'un Controller
    useEffect(() => {
        methods.setValue("childrenCounter", numberChild, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [numberChild]);

    // Permet de revalider la valeur du select retirementCategories suite à l'utilisation d'un Controller
    useEffect(() => {
        methods.setValue("retirementCategories", retirementType, {
            shouldValidate: formState.submitCount > 0,
        });
        setShowDetailFonctionnaire(retirementType === RetirementCategory.FONCTIONNAIRE);
    }, [retirementType]);

    useEffect(() => {
        methods.setValue("categorieActive", categorieActive, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [categorieActive, retirementType]);

    const formatDate = (date: Date) => {
        if (date) {
            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }

        return "01/01/1970";
    };

    useEffect(() => {
        if (family?.me?.birthday) {
            setDateOfBirthToShow(formatDate(new Date(family.me.birthday)));
            setDateOfBirth(new Date(family.me.birthday));
            setMaritalStatus(family.me.maritalStatus);
            setNumberChild(
                family.children.filter(
                    (child) =>
                        child.status === FamilyStatus.CHILD &&
                        (child.custodyType === CustodyType.FULL_CUSTODY || child.custodyType === CustodyType.FULL_CUSTODY_ME)
                ).length
            );
            if (family.me.retirementType) {
                setRetirementType(family.me.retirementType);
            }
        }
    }, [family, importData]);

    return (
    <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <SGTitle level={2} visuallevel={3}>
                {intl.formatMessage({ id: "tunnel.situation.title" })}
            </SGTitle>
            <SGText size={isPhone ? "l" : undefined}>
                {intl.formatMessage(
                    {
                        id: "tunnel.situation.dateOfBirth",
                    },
                    {
                        dateOfBirth: dateOfBirthToShow || "01/01/1970",
                        b: (word: string) => <strong style={{ fontFamily: "Source Sans Pro" }}>{word}</strong>,
                    }
                )}
            </SGText>
            <SGSpace direction="vertical" size="lg">
                <ChildrenCounterAssu
                    key="childrenCounterAssu"
                    counter={numberChild}
                    setCounter={setNumberChild}
                    max={max}
                    name="childrenCounter"
                />
                <Controller
                    key="retirementCategories"
                    control={methods.control}
                    name="retirementCategories"
                    rules={{
                        required: true,
                    }}
                    render={({ onChange }) => (
                        <SGSelect
                            placeholder="Sélectionnez"
                            value={retirementType || ""}
                            size={!isVisible ? "xl" : "l"}
                            onChange={(value: RetirementCategory) => {
                                onChange(value);
                                setRetirementType(value);
                                if (value !== RetirementCategory.FONCTIONNAIRE) {
                                    setCategorieActive(CategorieActiveChoix.NON);
                                }
                            }}
                            label={
                                <SGText>
                                    <SGTextIntl intlId="tunnel.situation.situationQuestion" size="l" />
                                </SGText>
                            }
                            status={methods?.errors?.retirementCategories && "error"}
                            validate={intl.formatMessage({ id: "tunnel.error.required" })}
                        >
                            <SGSelectOption value="" style={{ display: "none" }}>
                                Sélectionnez
                            </SGSelectOption>
                            {retirementTypeValues.map((retirementCategory: RetirementCategory) => (
                                <SGSelectOption key={retirementCategory} value={retirementCategory}>
                                    {intl.formatMessage({
                                        id: `retirementType.${retirementCategory}`,
                                    })}
                                </SGSelectOption>
                            ))}
                        </SGSelect>
                    )}
                />
                {retirementType === RetirementCategory.FONCTIONNAIRE && (
                    <Controller
                        key="categorieActive"
                        control={methods.control}
                        name="categorieActive"
                        rules={{
                            required: true,
                        }}
                        render={({ onChange }) => (
                            <SGSelect
                                placeholder="Sélectionnez"
                                value={categorieActive}
                                size={!isVisible ? "xl" : "l"}
                                onChange={(value: CategorieActiveChoix) => {
                                    onChange(value);
                                    setCategorieActive(value);
                                }}
                                label={
                                    <SGText>
                                        <SGTextIntl intlId="simulateurAvantApres.situation.question.categorieActive" size="l" />
                                        <InfoTooltipAssu
                                            text="simulateurAvantApres.situation.question.categorieActive.tooltip"
                                            placement={isPhone ? "bottomLeft" : "right"}
                                            transformations={{
                                                b: (word: string) => <SGText weight="bold">{word}</SGText>,
                                                linebreak: <br />,
                                            }}
                                        />
                                    </SGText>
                                }
                                status={methods?.errors?.categorieActive && "error"}
                                validate={intl.formatMessage({ id: "tunnel.error.required" })}
                            >
                                {optionCategorieActiveChoix.map((value: CategorieActiveChoix) => (
                                    <SGSelectOption key={value} value={value}>
                                        {value}
                                    </SGSelectOption>
                                ))}
                            </SGSelect>
                        )}
                    />
                )}
            </SGSpace>
            <SGBox margin={{ top: "xl" }}>
                <SGButtonGroup align={showReturn && !isPhone ? "opposite" : "center"} disableautomargin>
                    <SGButton onClick={handleSubmit(onSubmit)} size={[isPhone ? "md" : "sm", "md"]}>
                        {intl.formatMessage({ id: "tunnel.buttons.next" })}
                    </SGButton>
                    {showReturn && (
                        <SGButton type="link" onClick={() => dispatch(previousStepAction())}>
                            <SGText>{intl.formatMessage({ id: "tunnel.buttons.previous" })}</SGText>
                        </SGButton>
                    )}
                </SGButtonGroup>
            </SGBox>
        </form>
    </FormProvider>);
};

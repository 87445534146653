import { FunctionComponent, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { State } from "store/store";
import { previousStepAction } from "store/tunnel/actions";
import { WorkIncomeRaisesType, WorkIncomesHistory } from "store/workIncomesHistory/types";
import { Loader } from "website/components/atoms/Loader/Loader";
import { RadioGroup } from "website/components/atoms/RadioGroup/RadioGroup";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";

interface WorkIncomesForecastProps {
    onSubmit: (data: WorkIncomesHistory) => void;
    pastForecast?: WorkIncomeRaisesType;
    setPastForecast: (value: WorkIncomeRaisesType) => void;
    futureForecast?: WorkIncomeRaisesType;
    setFutureForecast: (value: WorkIncomeRaisesType) => void;
    hasSubmitStep: boolean;
    errorHappened: boolean;
    showErrorModal: boolean;
    handleError: (value: boolean) => void;
}

export const WorkIncomesForecast: FunctionComponent<WorkIncomesForecastProps> = (props: WorkIncomesForecastProps) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {
        onSubmit,
        pastForecast,
        setPastForecast,
        futureForecast,
        setFutureForecast,
        hasSubmitStep,
        errorHappened,
        showErrorModal,
        handleError,
    } = props;

    const methods = useForm({
        criteriaMode: "all",
        mode: "onSubmit",
        reValidateMode: "onChange",
    });

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "sm" });

    const { handleSubmit, formState } = methods;
    const isTunnelReforme = useSelector<State, boolean>((state) => state.tunnel.isTunnelReforme);

    const pastForecastList: WorkIncomeRaisesType[] = Object.values(WorkIncomeRaisesType);
    const futureForecastList: WorkIncomeRaisesType[] = Object.values(WorkIncomeRaisesType);

    const changePastForecast = (val: any) => {
        setPastForecast(val);
    };

    const changeFutureForecast = (val: any) => {
        setFutureForecast(val);
    };

    // Nécessaire pour la validation de la donnée pastForecast
    useEffect(() => {
        methods.setValue("pastForecast", pastForecast, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [pastForecast]);

    // Nécessaire pour la validation de la donnée futureForecast
    useEffect(() => {
        methods.setValue("futureForecast", futureForecast, {
            shouldValidate: formState.submitCount > 0,
        });
    }, [futureForecast]);

    const getRadioGroupPastForecast = (
        <RadioGroup
            title="tunnel.workIncomesForecast.evolutionSalairePasse"
            radioGroupList={pastForecastList}
            selectedValue={pastForecast || ""}
            setValue={changePastForecast}
            name="pastForecast"
            orientation="vertical"
        />
    );

    const getRadioGroupFutureForecast = (
        <RadioGroup
            title="tunnel.workIncomesForecast.evolutionSalaireFutur"
            radioGroupList={futureForecastList}
            selectedValue={futureForecast || ""}
            setValue={changeFutureForecast}
            name="futureForecast"
            orientation="vertical"
        />
    );

    return (
        <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
                    <SGGridRow gutter={[0,32]}>
                        <SGGridCol>
                            <SGTitle level={2} visuallevel={3}>{intl.formatMessage({ id: "tunnel.workIncomesForecast.title" })}</SGTitle>
                        </SGGridCol>
                        {getRadioGroupPastForecast}
                        {getRadioGroupFutureForecast}
                    </SGGridRow>
                {!hasSubmitStep ? (
                    <SGBox margin={{ top: "xl" }}>
                        <SGButtonGroup align={isPhone ? "center" : "opposite"} disableautomargin>
                            <SGButton type="primary" onClick={handleSubmit(onSubmit)}>
                                {errorHappened && intl.formatMessage({ id: "tunnel.workIncomesForecast.button.retry" })}
                                {!errorHappened && intl.formatMessage({ id: isTunnelReforme ? "tunnel.workIncomesForecast.reforme.button" : "tunnel.workIncomesForecast.button" })}
                            </SGButton>
                            <SGButton
                                type="link"
                                onClick={() => dispatch(previousStepAction())}
                            >
                                <SGText>{intl.formatMessage({ id: "tunnel.buttons.previous" })}</SGText>
                            </SGButton>
                        </SGButtonGroup>
                    </SGBox>
                ) : (
                    <SGBox margin={{top: "sm"}}>
                        <Loader title="loader.title" />
                    </SGBox>
                )}
                {showErrorModal && (
                    <ErrorModal visible={showErrorModal} setVisible={handleError} />
                )}
            </form>
        </FormProvider>);
}

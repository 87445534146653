import { useEffect, useState } from "react";
import { ARTICLE, ARTICLES } from "website/utils/privateRoutes";
import { SousThematique, Thematique } from "../molecules/Article/ArticleModel";
import { Article, articles, articlesCompareParDate } from "./articles";

type SousThematiques = {
  [key in Thematique]: SousThematique[];
};

const useArticles = () => {
  const [thematiques, setThematiques] = useState<Thematique[]>([]);
  const [sousThematiques, setSousThematiques] = useState<SousThematiques>();
  const [articlesTries, setArticlesTries] = useState<Article[]>([]);

  /**
   * Permet d'avoir la liste des thematiques présentées dans la liste des articles
   * @param paramArticles la liste des articles
   * @returns la liste des thematiques
   */
  const getThematiques = (paramArticles: Article[]): Thematique[] => {
    const thematiquesArticles = Array.from(
      new Set(paramArticles.map((item) => item.theme))
    );

    return Object.values(Thematique).filter((thematique) =>
      thematiquesArticles.includes(thematique)
    );
  };

  /**
   * Détermine la liste des sous-thématiques utilisées par les articles.
   * @param paramArticles Liste des articles
   * @returns La liste des sous-thématiques par thématique
   */
  const getSousThematiques = (paramArticles: Article[]): SousThematiques => {
    const obj: any = {};
    Object.keys(Thematique).forEach((thematique) => {
      obj[thematique] = Array.from(
        new Set(
          paramArticles
            .filter(
              (article) =>
                article.theme === thematique && article.sousThematique
            )
            .map((item) => item.sousThematique)
        )
      );
    });

    return obj;
  };

  const getLienThematique = (nomThematique: string) => {
    const indexThematique =
      thematiques.findIndex(
        (thematique: Thematique) => thematique === nomThematique
      ) ?? -1;

    // On ajoute 1 car le premier onglet est l'accueil
    return `${ARTICLES}?tab=${indexThematique + 1}`;
  };

  /**
   * Construit l'URL vers un article
   *
   * @param nomArticle Chemin vers l'article au format snake-case
   * @returns L'url pour redirection interne (history.push)
   */
  const getLienArticle = (nomArticle: string) => ARTICLE + nomArticle;

  useEffect(() => {
    setThematiques(getThematiques(articles));
    setSousThematiques(getSousThematiques(articles));
    articles.sort(articlesCompareParDate);
    setArticlesTries(articles);
  }, []);

  return {
    thematiques,
    sousThematiques,
    articlesTries,
    getLienThematique,
    getLienArticle,
  };
};

export { useArticles };

import React, { PropsWithChildren, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { useIntl } from "react-intl";
import { SGButton } from "sg-button";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGInputNumber } from "sg-input";
import { SGPopover } from "sg-popover";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGSpace } from "sg-space";
import { SGTextIntl } from "../SGTextIntl/SGTextIntl";

interface NumericInputProps {
   label?: string;
   labelSize?: "s" | "m" | "l";
   labelTransformations?: Record<string, string | React.ReactNode>;
   tooltipLabel?: string;
   tooltipTransformations?: Record<string, string | React.ReactNode>;
   defaultValue?: number;
   name: string;
   placeholder?: string;
   required?: boolean;
   min?: number;
   minValueInput?: number;
   minMessage?: string;
   max?: number;
   maxValueInput?: number;
   maxMessage?: string;
   disabled?: boolean;
   suffix?: string;
   onValueChange?: (numericValue: number) => void;
   displayPeriodicChoice?: boolean;
   cypressName: string;
   overrideDefaultValue?: boolean;
   disableautomargin? : boolean;
   onBlurInput?: () => void;
}

const notTranslatableSuffixes = ["€", "m²", "%", " "];

export enum MonthlyYearly {
   MONTHLY = "MONTHLY",
   YEARLY = "YEARLY",
}

const NumericInput = ({
                         label, labelSize = "s", labelTransformations, tooltipLabel, tooltipTransformations, defaultValue, name, placeholder, required, min, minValueInput, minMessage,
                         max, maxValueInput, maxMessage, disabled, suffix, onValueChange, displayPeriodicChoice, cypressName, overrideDefaultValue = false, disableautomargin = false, onBlurInput
                      }: PropsWithChildren<NumericInputProps>) => {
   const {errors, control, setValue} = useFormContext();
   const intl = useIntl();

   const suffixVal = (function intlSuffix(pSuffix) {
      if (pSuffix) {
         if (notTranslatableSuffixes.includes(pSuffix)) {
            return ` ${pSuffix}`;
         }

         return ` ${intl.formatMessage({id: pSuffix})}`;
      }

      return pSuffix;
   }(suffix));

   useEffect(() => {
      setValue(name, defaultValue);
   }, [defaultValue]);

   const validationRules: RegisterOptions = {};
   if (!disabled) {
      // no validation when read only
      validationRules.required = required === undefined ? true : required;
      if (min !== undefined) {
         validationRules.min = {value: min, message: ""};
      }
      if (max !== undefined) {
         validationRules.max = {value: max, message: ""};
      }
   }

   const formatNumber = (value: number | undefined) =>
      (!value ? "#VAL" : intl.formatNumber(value)) +
      suffixVal?.replace(" ", "\u00a0");

   const computeErrorMessage = (): string => {
      if (errors && errors[name]) {
         switch (errors[name].type) {
            case "min":
               return intl.formatMessage({id: minMessage || "common.validation.numeric-value.min"}, min ? {min: formatNumber(min)} : {});
            case "max":
               return intl.formatMessage({id: maxMessage || "common.validation.numeric-value.max"}, max ? {max: formatNumber(max)} : {});
            case "required":
            default:
               return intl.formatMessage({id: "common.required"});
         }
      }

      return "";
   }

   const computeLabelNode = (pLabel: string): React.ReactNode => {
      if (tooltipLabel) {
         return <>
            <SGTextIntl intlId={pLabel} transformations={labelTransformations} size={labelSize}/>
            <SGPopover trigger="hover" content={<SGTextIntl intlId={tooltipLabel} transformations={tooltipTransformations}/>} placement="topLeft">
               <SGButton type="icon" icon={<SGIcon component={<SGAvenirStatusInfo/>}/>} tabIndex="-1"/>
            </SGPopover>
         </>
      }

      return <SGTextIntl intlId={pLabel} transformations={labelTransformations} size={labelSize} />;
   }

   return (
      <SGSpace disableautomargin={disableautomargin}>
            <Controller key="controller1" control={control} name={name} defaultValue={defaultValue === undefined ? null : defaultValue} rules={validationRules}
                        render={({onChange, onBlur, value}) => (
                           <SGInputNumber label={label && computeLabelNode(label)} defaultValue={defaultValue} value={overrideDefaultValue ? defaultValue : value} placeholder={placeholder} data-cy={cypressName}
                                          disabled={disabled}
                                          status={(errors && errors[name]) ? "error" : "default"} validate={computeErrorMessage()}
                                          inputMode="numeric" unitvalue={suffixVal}
                                          min={minValueInput ?? min ?? -Number.MAX_SAFE_INTEGER} max={maxValueInput ?? max}
                                          onBlur={() => {
                                             onBlur();
                                             if (onBlurInput) {
                                                onBlurInput();
                                             }
                                          }}
                                          onChange={(valueChanged: number) => {
                                             onChange(valueChanged);
                                             if (onValueChange) {
                                                onValueChange(valueChanged);
                                             }
                                          }}/>
                        )}/>
         {displayPeriodicChoice &&
            <Controller key="controller2" name={`${name}Periodicity`} defaultValue={MonthlyYearly.MONTHLY}
                        render={({onChange, value}) => (
                           <SGSelect onSelect={(pValue: string) => onChange(pValue)} label="&nbsp;" value={value}>
                              <SGSelectOption value={MonthlyYearly.MONTHLY}>
                                 {intl.formatMessage({
                                    id: `form.input.numeric.${MonthlyYearly.MONTHLY}`,
                                 })}
                              </SGSelectOption>
                              <SGSelectOption value={MonthlyYearly.YEARLY}>
                                 {intl.formatMessage({
                                    id: `form.input.numeric.${MonthlyYearly.YEARLY}`,
                                 })}
                              </SGSelectOption>
                           </SGSelect>
                        )}/>
         }
      </SGSpace>
   );
};

export { NumericInput };

import { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGAlert } from "sg-alert";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGTitle } from "sg-typo";
import { goToStep, setNewSimulation } from "store/simulateurEconomiesImpots/slice";
import { ERROR_ECONOMY_SIMULATION_API, SimulateurEconomiesImpotsState } from "store/simulateurEconomiesImpots/types";
import { SupervisionState, possedeDroitsActionUtilisateur } from "store/supervision/types";
import { useFamily } from "website/components/hooks/useFamily";
import { useSimulateurEconomiesImpotsState } from "website/components/hooks/useSimulateurEconomiesImpotsState";
import { DisclaimerSeiModal } from "website/components/molecules/DisclaimerSeiModal/DisclaimerSeiModal";
import { StepsAssu as Steps } from "website/components/molecules/StepsAssu/StepsAssu";
import { PlafondStep } from "website/components/organisms/SimulateurEconomiesImpots/PlafondStep/PlafondStep";
import { RevenusStep } from "website/components/organisms/SimulateurEconomiesImpots/RevenusStep/RevenusStep";
import { SituationStep } from "website/components/organisms/SimulateurEconomiesImpots/SituationStep/SituationStep";
import { VersementStep } from "website/components/organisms/SimulateurEconomiesImpots/VersementStep/VersementStep";
import { State } from "../../store/store";
import { BreadCrumbAssu } from "../components/molecules/BreadCrumbAssu/BreadCrumbAssu";
import { SimulateurEconomiesImpots } from "../components/templates/SimulateurEconomiesImpots/SimulateurEconomiesImpots";

const SimulateurEconomiesImpotsPage: FunctionComponent = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const [reset, setReset] = useState(false);
    const currentStep = useSelector<State, number>((state) => state.simulateurEconomiesImpots.currentStep);
    const simulateurEconomiesImpotsState: SimulateurEconomiesImpotsState = useSimulateurEconomiesImpotsState();
    // initialisation du state family à l'entrée du parcours pour alimenter des champs au premier parcours
    const family = useFamily();

    const isPhone = useMediaQuery({ minwidth: 'xs', maxwidth: 'xs' });

    const supervisionState: SupervisionState = useSelector<State,SupervisionState>((state) => state.supervision);

    const steps: FunctionComponent[] = [SituationStep, RevenusStep, PlafondStep, VersementStep];

    const simulateurEconomiesImpotsSteps = [
        intl.formatMessage({ id: "simulateurEconomiesImpots.step.situation" }),
        intl.formatMessage({ id: "simulateurEconomiesImpots.step.revenus" }),
        intl.formatMessage({ id: "simulateurEconomiesImpots.step.plafond" }),
        intl.formatMessage({ id: "simulateurEconomiesImpots.step.versement" }),
    ];

    const idTrackingSteps = ["ma-situation", "mes-revenus", "mon-plafond-fiscal", "mon-economie-impot"];

    // Réinitialisation du parcours
    useEffect(() => {
        if (simulateurEconomiesImpotsState.hasFetched || simulateurEconomiesImpotsState.taxEconomySimulationError?.includes(ERROR_ECONOMY_SIMULATION_API)) {
            dispatch(goToStep(simulateurEconomiesImpotsState.savedParameters.payment === undefined ? 0 : 3))
            dispatch(setNewSimulation(simulateurEconomiesImpotsState.savedParameters.payment === undefined));
            setReset(true);
        }
    }, [simulateurEconomiesImpotsState.hasFetched]);

    return reset ? (
        <>
            <div className="containerAssu">
                <SGMediaQuery minwidth="sm">
                    <SGGridRow>
                        <SGGridCol span={12}>
                            <BreadCrumbAssu step="simulateurEconomiesImpots.header" idTracking={idTrackingSteps[currentStep]} />
                        </SGGridCol>
                    </SGGridRow>
                    <SGGridRow>
                    {!possedeDroitsActionUtilisateur(supervisionState) &&
                        simulateurEconomiesImpotsState.savedParameters.payment === undefined && (
                            <SGGridCol span={12}>
                              <SGBox margin={{ bottom: "md" }}>
                                <SGAlert
                                  disableautomargin
                                  icon={<SGIcon component={<SGAvenirStatusInfo />} size="s" />}
                                  message={intl.formatMessage({
                                    id: "simulateurEconomiesImpots.alert.supersivion",
                                  })}
                                  span={10}
                                  type="info"
                                />
                              </SGBox>
                            </SGGridCol>
                          )}
                        <SGGridCol span={12}>
                            <SGTitle level={1} visuallevel={2} caps textalign="center">
                                {intl.formatMessage({ id: "simulateurEconomiesImpots.header" })}
                            </SGTitle>
                        </SGGridCol>
                    </SGGridRow>
                </SGMediaQuery>
                <SGMediaQuery minwidth="xs" maxwidth="xs">
                    <SGGridRow>
                        <SGGridCol span={12}>
                            <SGBox margin={{ top: "md" }}>
                                <SGTitle level={1} visuallevel={2} caps>
                                    {intl.formatMessage({ id: "simulateurEconomiesImpots.header" })}
                                </SGTitle>
                            </SGBox>
                        </SGGridCol>
                    </SGGridRow>
                </SGMediaQuery>
                <SGGridRow className="simulateurEconomiesImpots__step-header" justify="center">
                    <SGGridCol span={12}>
                        <Steps currentStep={currentStep} steps={simulateurEconomiesImpotsSteps} />
                    </SGGridCol>
                </SGGridRow>
            </div>
            <div className="simulateurEconomiesImpots__step-container">
                <div className="containerAssu simulateurEconomiesImpots__padding-container">
                    <SGGridRow className="simulateurEconomiesImpots__step-content">
                        <SGGridCol span={12}>
                            <SGGridRow className="simulateurEconomiesImpots__step-header">
                                <SGGridCol span={12} align="start">
                                    <SGBox margin={isPhone ? {right: "md", left: "md"} : {right: "xxl", left: "xxl"}}>
                                    <SimulateurEconomiesImpots currentStep={currentStep} steps={steps} />
                                    </SGBox>
                                </SGGridCol>
                            </SGGridRow>
                        </SGGridCol>
                    </SGGridRow>
                </div>
            </div>
            <DisclaimerSeiModal />
        </>
    ) : <></>;
};

export { SimulateurEconomiesImpotsPage };

import { FunctionComponent, useEffect, useState } from "react";
import { SGModal } from "sg-modal";
import { SGButton, SGButtonGroup } from "sg-button";
import { useIntl } from "react-intl";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { useHistory } from "react-router";
import { DASHBOARD } from "website/utils/privateRoutes";
import { deleteProfile } from "store/modifierProfil/actions";
import { useDispatch, useSelector } from "react-redux";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { SGBox, SGSpace } from "sg-space";
import { useMediaQuery } from "sg-media-query";
import { logout } from "store/login/actions";
import { State } from "store/store";
import { PAGE_CATEGORY_3_MODIFIER_PROFIL } from "website/components/hooks/tracking/types";
import { SGSpinner } from "sg-spinner";

interface AlertDeleteProfileModalProps {
  isVisible: boolean;
  setVisible: (isVisible: boolean) => void;
}

const AlertDeleteProfileModal: FunctionComponent<
  AlertDeleteProfileModalProps
> = (props: AlertDeleteProfileModalProps) => {
  const { isVisible, setVisible } = props;
  const intl = useIntl();
  const history = useHistory();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const dispatch = useDispatch();
  const { trackPopin, resetTracking } = useTracking();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);

  const estSupervisionActive: boolean = useSelector<State, boolean>(
    (state) => state.supervision.estActive
  );

  useEffect(() => {
    if (isVisible) {
      trackPopin(
        false,
        PAGE_CATEGORY_3_MODIFIER_PROFIL,
        "popin-supprimer-mes-donnees"
      );
    }
  }, [isVisible]);

  const constantIsDone: boolean = useSelector<State, boolean>(
    (state) => state.profile.isDone
  );

  useEffect(() => {
    if (constantIsDone) {
      dispatch(logout());
    }
  }, [constantIsDone]);

  return (
    <SGModal
      visible={isVisible}
      onCancel={() => {
        if (!showSpinner) {
          setVisible(false);
          trackPopin(
            true,
            PAGE_CATEGORY_3_MODIFIER_PROFIL,
            "popin-supprimer-mes-donnees::clic-sur-fermer"
          );
          resetTracking();
        }
      }}
    >
      <SGSpace direction="vertical">
        <SGBox key="box-1" forcemargin margin={isPhone ? { top: "sm" } : {}}>
          <SGTitle level={1}>
            {intl.formatMessage({
              id: "modifierProfil.modalSuppression.titre",
            })}
          </SGTitle>
        </SGBox>
        <SGBox key="box-2" margin={{ top: "sm" }}>
          <SGText size="l">
            {intl.formatMessage(
              { id: "modifierProfil.modalSuppression.texte" },
              {
                b: (word: string) => <SGText strong>{word}</SGText>,
                p: (word: string) => <SGBlock component="p">{word}</SGBlock>,
              }
            )}
          </SGText>
        </SGBox>
      </SGSpace>
      {showSpinner ? (
        <SGBox margin={{ top: "md" }}>
          <SGSpinner size="md" />
        </SGBox>
      ) : (
        <SGButtonGroup align="center" layout="row">
          <SGButton
            type="primary"
            size={["sm", "md"]}
            disabled={estSupervisionActive}
            onClick={() => {
              setShowSpinner(true);
              trackPopin(
                true,
                PAGE_CATEGORY_3_MODIFIER_PROFIL,
                "popin-supprimer-mes-donnees::clic-sur-oui"
              );
              dispatch(deleteProfile());
            }}
          >
            Oui
          </SGButton>

          <SGButton
            type="secondary"
            size={["sm", "md"]}
            onClick={() => {
              setVisible(false);
              history.push(DASHBOARD);
              trackPopin(
                true,
                PAGE_CATEGORY_3_MODIFIER_PROFIL,
                "popin-supprimer-mes-donnees::clic-sur-non"
              );
            }}
          >
            Non
          </SGButton>
        </SGButtonGroup>
      )}
    </SGModal>
  );
};

export { AlertDeleteProfileModal };

import { ReactComponent as Arrow } from "assets/images/arrow-right-solid.svg";
import React, { FunctionComponent } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { SGAlert } from "sg-alert";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirStatusInfo } from "sg-icon-pack-base";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { Media, TypeMedia } from "website/components/hooks/articles";
import { CategorieVideo } from "website/components/hooks/useVideos";
import { BreadCrumbAssu } from "website/components/molecules/BreadCrumbAssu/BreadCrumbAssu";
import { ARTICLES, DASHBOARD } from "website/utils/privateRoutes";
import { HomeCaroussel } from "../../molecules/HomeCaroussel/HomeCaroussel";
import { CarrouselVideo } from "../CarrouselVideo/CarrouselVideo";

import { ReactNodeLike } from "prop-types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import "./MediaTemplate.scss";

interface MediaTemplateProps {
  media: Media;
  titre: string;
  image?: JSX.Element;
  icone: ReactNodeLike;
}

export const MediaTemplate: FunctionComponent<MediaTemplateProps> = (
  props: React.PropsWithChildren<MediaTemplateProps>
) => {
  const { media, titre, image, icone, children } = props;

  const intl = useIntl();
  const history = useHistory();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const { trackClick } = useTracking();

  const dateformat = new Intl.DateTimeFormat("fr-FR", {
    month: "long",
    year: "numeric",
  }).format;

  const goToDashboard = () => {
    trackClick(`${media.path}::clic-sur-retour-a-mon-tableau-de-bord`);
    history.push(DASHBOARD);
  };

  return (
    <div className="containerAssu">
      <SGMediaQuery minwidth="sm">
        <SGGridRow>
          <SGGridCol>
            <BreadCrumbAssu
              step={titre}
              retour={ARTICLES}
              idTracking={media.path}
            />
          </SGGridCol>
        </SGGridRow>
      </SGMediaQuery>
      <SGGridRow justify="center" gutter={[0, 30]}>
        <SGGridCol span={12} sm={10} textalign="center">
          <SGBox margin={isPhone ? { top: "md" } : {}}>
            <SGTitle level={1}>{titre}</SGTitle>
          </SGBox>
        </SGGridCol>
        <SGGridCol align="center">
          <SGGridRow justify="center" align="middle">
            <SGMediaQuery minwidth="sm">
              <SGGridCol>
                <SGText className="media-template__thematique-red-color">
                  {intl.formatMessage({ id: `articles.tab.${media.theme}` })}
                </SGText>
              </SGGridCol>
              <SGGridCol className="media-template__line" />
            </SGMediaQuery>
            <SGMediaQuery minwidth={0} maxwidth="xs">
              <SGGridCol xs={12} textalign="center">
                <SGText className="media-template__thematique-red-color">
                  {intl.formatMessage({ id: `articles.tab.${media.theme}` })}
                </SGText>
              </SGGridCol>
            </SGMediaQuery>
            <SGGridCol>
              <SGText className="media-template__date">
                {dateformat(media.date)}
              </SGText>
            </SGGridCol>
            <SGGridCol className="media-template__line" />
            <SGGridCol align="center">
                <SGText>
                  <SGIcon size="s" currentcolor component={icone} align="text-top" />
                  {' '}
                  {media.time} MIN
                </SGText>
              </SGGridCol>
          </SGGridRow>
        </SGGridCol>
        {image && media.type === TypeMedia.ARTICLE && (
          <SGGridCol span={12} textalign="center">
            {image}
          </SGGridCol>
        )}
      </SGGridRow>
      {media.type === TypeMedia.ARTICLE && (
        <SGGridRow>
          <SGGridCol span={12}>
            <SGBox margin={{ top: "md" }}>
              <SGAlert
                description={
                  <SGText size="m">
                    Les informations contenues dans ce document sont exclusivement
                    de nature générale et non exhaustive. Le contenu est proposé à
                    titre informatif et ne constitue en aucun cas un conseil de la
                    part de Société Générale et ne peut se substituer au conseil
                    personnalisé dispensé par un professionnel afin de vous
                    proposer une solution adaptée eu égard de votre situation
                    personnelle.
                  </SGText>
                }
                icon={<SGIcon component={<SGAvenirStatusInfo />} size="l" />}
                type="info"
                span={9}
              />
            </SGBox>
          </SGGridCol>
        </SGGridRow>
      )}
      <SGBox margin={{ top: "xl" }}>
        <SGGridRow justify="center">
          <SGGridCol span={12} sm={8}>
            <SGGridRow>
              <SGGridCol span={12}>{children}</SGGridCol>
              <SGGridCol span={12}>
                <SGBox
                  margin={media.type === TypeMedia.VIDEO ? { top: "sm" } : {}}
                >
                  <SGButton
                    className="media-template__retour-button"
                    type="tertiary"
                    icon={
                      <SGIcon
                        rotate="left"
                        component={<Arrow className="media-template__arrow" />}
                      />
                    }
                    iconposition="left"
                    onClick={goToDashboard}
                  >
                    {intl.formatMessage({
                      id: "parcoursRIS.preparation.link.retourBilanRetraite",
                    })}
                  </SGButton>
                </SGBox>
              </SGGridCol>
            </SGGridRow>
          </SGGridCol>
        </SGGridRow>
      </SGBox>
      <SGBox margin={{ top: "xl" }}>
      <>
        <SGTitle
          level={2}
        >
          {intl.formatMessage({ id: "home.media-template.caroussel" })}
        </SGTitle>
        <HomeCaroussel
          currentArticlePath={media.path}
          blockTracking="a-lire-aussi"
        />
      </>
      </SGBox>
      <SGBox margin={{ top: "lg", bottom: "lg" }}>
        <CarrouselVideo
          categorie={CategorieVideo.ARTICLES}
          videoPathCourante={media.path}
        />
      </SGBox>
    </div>
  );
};

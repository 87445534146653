import { NomNosSolutionsEnum } from "website/components/molecules/NosSolutions/utils";
import { ArticlesPage } from "website/pages/ArticlesPage";
import { CheckListPage } from "website/pages/CheckListPage";
import { LogoutPage } from "website/pages/LogoutPage";
import { MediaPage } from "website/pages/MediaPage/MediaPage";
import { MesServicesPage } from "website/pages/MesServicesPage";
import { ModifierProfilPage } from "website/pages/ModifierProfilPage";
import { MonObjectifPage } from "website/pages/MonObjectifPage";
import { MonPlanPage } from "website/pages/MonPlanPage";
import { NosSolutionsAsvPage } from "website/pages/NosSolutionsAsvPage";
import { NosSolutionsPerPage } from "website/pages/NosSolutionsPerPage";
import { ReformeDepartsAnticipesPage } from "website/pages/ReformeDepartsAnticipesPage";
import { SGAgendaConnexionPage } from "website/pages/SGAgendaConnexionPage";
import { SGAgendaPage } from "website/pages/SGAgendaPage";
import { SimulateurAvantApresPage } from "website/pages/SimulateurAvantApresPage";
import { SimulateurEconomiesImpotsPage } from "website/pages/SimulateurEconomiesImpotsPage";
import { SimulateurFlashPage } from "website/pages/SimulateurFlashPage";
import { DashboardPage } from "../pages/DashboardPage";
import { ParcoursRISPage } from "../pages/ParcoursRISPage";
import { ParcoursRevenusComplementairesPage } from "../pages/ParcoursRevenusComplementairesPage";
import { TunnelPage } from "../pages/TunnelPage";

/** TODO A SUPPRIMER DEBUT */
export const ADVICES = "/advices";
export const EDIT_INCOMES = "/edit-incomes";
export const EDIT_EXPENSES = "/edit-expenses";
export const EDIT_TAX_CREDITS = "/edit-tax-credits";
export const EDIT_REALIZED_DONATIONS = "/edit-realized-donations";
export const EDIT_CHILD_SUPPORT_EXPENSES = "/edit-child-support-expense";
export const EDIT_LOANS = "/edit-loans";
export const EDIT_ASSETS = "/edit-assets";
export const INHERITANCE_SIMULATOR = "/inheritance-simulator";
export const NEW_PROJECTS = "/new-projects";
export const PROJECTS = "/projects";
export const SIMULATIONS = "/simulations";
export const RETIREMENT_PROJECT = "/retirement-project";
export const REAL_ESTATE_PROJECT = "/real-estate-project";
export const CONSTRUCTION_WORK_PROJECT = "/construction-work";
export const EXCEPTIONAL_EXPENSE_PROJECT = "/exceptional-expense-project";
export const WEDDING_PROJECT = "/wedding-project";
export const CAPITAL_GAIN_PROJECT = "/capital-gain-project";
export const TAX_EXEMPTION_PROJECT = "/tax-exemption-project";
export const ADDITIONAL_INCOME_PROJECT = "/additional-income-project";
export const CHILDREN_EDUCATION_PROJECT = "/children-education-project";
export const RECEIVED_DONATION_PROJECT = "/received-donation-project";
export const GIVEN_DONATION_PROJECT = "/given-donation-project";
export const TRAVEL_PROJECT = "/travel-project";
export const CHANGE_LIFE_PROJECT = "/change-life-project";
export const SITUATION = "/situation";
export const SITUATION_FAMILY = "/situation/family";
export const SITUATION_NET_WORTH = "/situation/net-worth";
export const SITUATION_INCOMES = "/situation/incomes";
export const SITUATION_TAXATION = "/situation/taxation";
export const SITUATION_SUCCESSION = "/situation/succession";
/** TODO A SUPPRIMER FIN */

// Pages de l'application
export const TUNNEL = "/tunnel";
export const DASHBOARD = "/dashboard";
export const ARTICLES = "/articles";
export const ARTICLE = "/articles/";
export const MON_PLAN = "/mon-plan";
export const MES_SERVICES = "/mes-services";
export const PARCOURS_REVENUS_COMPLEMENTAIRES =
  "/parcours-revenus-complementaires";
export const PARCOURS_RIS = "/parcours-ris";
export const BESOIN_EPARGNE = "/besoin-epargne";
export const SIMULATEUR_FLASH = "/simulateur-flash";
export const MA_CHECKLIST = "/ma-checklist/:checkListType";
export const SIMULATEUR_ECONOMIES_IMPOTS = "/simulateur-economies-impots";
export const LOGOUT = "/logout";
export const SIMULATEUR_AVANT_APRES = "/simulateur-avant-apres";
export const REFORME_DEPART_ANTICIPE = "/reforme-departs-anticipes";
export const MODIFIER_PROFIL = "/modifier-profil";
export const SGAGENDA = "/sgagenda";
export const SGAGENDA_CONNEXION = "/sgagenda-connexion";
export const NOS_SOLUTIONS_ASV = `/nos-solutions/${NomNosSolutionsEnum.ASV}`;
export const NOS_SOLUTIONS_PER = `/nos-solutions/${NomNosSolutionsEnum.PER}`;

// Pages bloquantes
export const PAGES_BLOQUANTES = [TUNNEL, REFORME_DEPART_ANTICIPE];

export const privateRoutes = [
  // Navigation
  { path: TUNNEL, component: TunnelPage },
  { path: DASHBOARD, component: DashboardPage },
  {
    path: PARCOURS_RIS,
    component: ParcoursRISPage,
    errorPage: DASHBOARD,
  },
  { path: ARTICLES, component: ArticlesPage },
  { path: `${ARTICLE}:mediaPath`, component: MediaPage },
  {
    path: PARCOURS_REVENUS_COMPLEMENTAIRES,
    component: ParcoursRevenusComplementairesPage,
    errorPage: DASHBOARD,
  },
  { path: BESOIN_EPARGNE, component: MonObjectifPage },
  { path: SGAGENDA, component: SGAgendaPage },
  {
    path: SGAGENDA_CONNEXION,
    component: SGAgendaConnexionPage,
    errorPage: DASHBOARD,
  },
  { path: MON_PLAN, component: MonPlanPage },
  { path: MA_CHECKLIST, component: CheckListPage },
  { path: MES_SERVICES, component: MesServicesPage },
  {
    path: SIMULATEUR_FLASH,
    component: SimulateurFlashPage,
    errorPage: DASHBOARD,
  },
  {
    path: SIMULATEUR_ECONOMIES_IMPOTS,
    component: SimulateurEconomiesImpotsPage,
    errorPage: DASHBOARD,
  },
  { path: LOGOUT, component: LogoutPage },
  {
    path: SIMULATEUR_AVANT_APRES,
    component: SimulateurAvantApresPage,
    errorPage: DASHBOARD,
  },
  { path: REFORME_DEPART_ANTICIPE, component: ReformeDepartsAnticipesPage },
  { path: MODIFIER_PROFIL, component: ModifierProfilPage },
  { path: NOS_SOLUTIONS_PER, component: NosSolutionsPerPage },
  { path: NOS_SOLUTIONS_ASV, component: NosSolutionsAsvPage },
];

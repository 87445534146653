import { FC, useMemo } from "react";
import { useHistory } from "react-router";
import { SGButton } from "sg-button";
import { SGCarousel } from "sg-carousel";
import { useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import {
  Article,
  TypeMedia,
  articles,
  getIdTrackingType,
} from "website/components/hooks/articles";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { CarteArticleVertical } from "website/components/organisms/Articles/CarteArticleVertical";
import { ARTICLE, ARTICLES } from "website/utils/privateRoutes";

interface AccesContenusProps {
  idArticles: string[];
  nbSlides?: number;
  prefixeTracking?: string;
}
const AccesContenus: FC<AccesContenusProps> = (props) => {
  const { idArticles, nbSlides = 2, prefixeTracking = "" } = props;

  const history = useHistory();
  const { trackClick } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  const articlesCarrousel: Article[] = useMemo(
    () =>
      idArticles
        .map((id) => articles.find((article) => article.id === id))
        // Si non trouvé, on ignore
        .filter((article): article is Article => !!article),
    [idArticles]
  );

  const handleOnClickLireArticle = (
    path: string,
    trackKey: string,
    typeMedia?: TypeMedia
  ) => {
    const idTrackingType: string = typeMedia
      ? `-${getIdTrackingType(typeMedia)}-`
      : "";
    trackClick(`${prefixeTracking}${trackKey}${idTrackingType}${path}`);
    history.push(`${ARTICLE}${path}`);
  };

  const handleOnClick = () => {
    trackClick(`${prefixeTracking}clic-sur-voir-tous-les-articles`);
    history.push(ARTICLES);
  };

  return (
    <SGSpace direction="vertical" size="md" disableautomargin>
      <SGBox key="box-1">
        <>
          <SGTitle level={2} visuallevel={isPhone ? 2 : 3}>
            Nos conseils d’experts
          </SGTitle>
          <SGText>Des contenus pour en apprendre plus sur la retraite</SGText>
        </>
      </SGBox>
      <SGCarousel
        key="carousel"
        slidesToScroll={1}
        settings={{
          infinite: true,
          slidesToShow: nbSlides,
        }}
      >
        {articlesCarrousel.map((article) => (
          <CarteArticleVertical
            key={article.id}
            media={article}
            handleOnClickLireArticle={handleOnClickLireArticle}
            trackKey="clic-sur-carrousel"
            afficherIconeTag={false}
          />
        ))}
      </SGCarousel>
      <SGBox key="box-2" textalign="center">
        <SGButton type="secondary" onClick={handleOnClick}>
          Voir tous les articles
        </SGButton>
      </SGBox>
    </SGSpace>
  );
};

export { AccesContenus };

import { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { nextStep } from "store/parcoursRIS/action";
import { updateParcoursRisInformationsComplementaires } from "store/parcoursRisInformationsComplementaires/actions";
import { ParcoursRisInformationsComplementairesState } from "store/parcoursRisInformationsComplementaires/types";
import { updateParcoursRisProjection } from "store/parcoursRisProjection/actions";
import { ParcoursRisProjectionState } from "store/parcoursRisProjection/types";
import {
  postParcoursRisUpload,
  resetParcoursRisUploadError,
} from "store/parcoursRisUpload/actions";
import {
  ParcoursRisUploadRequest,
  ParcoursRisUploadState,
  hasFetchedParcoursRisUpload,
} from "store/parcoursRisUpload/types";
import { State } from "store/store";
import {
  PAGE_CATEGORY_3_RIS,
  PAGE_TYPE_FORMULAIRE,
} from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import {
  trackingEvent,
  useTrackingEvent,
} from "website/components/hooks/useTrackingEvent";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import { LoaderParcoursRis } from "website/components/molecules/LoaderParcoursRis/LoaderParcoursRis";
import { DASHBOARD } from "website/utils/privateRoutes";
import { ParcoursRISCarteCommentFaire } from "./ParcoursRISCarteCommentFaire";
import { ParcoursRISCarteImport } from "./ParcoursRISCarteImport";
import { ParcoursRISCarteVideo } from "./ParcoursRISCarteVideo";

const ParcoursRISPreparation: FunctionComponent = () => {
  const dispatch = useDispatch();
  const saveEvent = useTrackingEvent();
  const history = useHistory();
  const intl = useIntl();
  const { trackClick, trackPage } = useTracking();

  const parcoursRisUploadState: ParcoursRisUploadState = useSelector<
    State,
    ParcoursRisUploadState
  >((state) => state.parcoursRisUpload);

  const parcoursRisInformationsComplementairesState: ParcoursRisInformationsComplementairesState =
    useSelector<State, ParcoursRisInformationsComplementairesState>(
      (state) => state.parcoursRisInformationsComplementaires
    );

  const parcoursRisProjectionState: ParcoursRisProjectionState = useSelector<
    State,
    ParcoursRisProjectionState
  >((state) => state.parcoursRisProjection);

  const [uploadedFile, setUploadedFile] = useState<File>();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorFormat, setErrorFormat] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  useEffect(() => {
    trackPage(
      PAGE_CATEGORY_3_RIS,
      "preparation",
      PAGE_TYPE_FORMULAIRE,
      "simulation",
      "1"
    );
    saveEvent(trackingEvent.RIS_PREPARATION);
    dispatch(
      updateParcoursRisInformationsComplementaires({
        ...parcoursRisInformationsComplementairesState,
        success: false,
      })
    );
    dispatch(
      updateParcoursRisProjection({
        ...parcoursRisProjectionState,
        date_parcours: undefined,
      })
    );
  }, []);

  const onSubmit = () => {
    dispatch(resetParcoursRisUploadError());
    trackClick("preparation::clic-sur-commencer-mon-estimation");
    setErrorFormat(false);
    if (uploadedFile) {
      if (uploadedFile.type !== "application/pdf") {
        setErrorFormat(true);
        manageErrorApi();
      } else {
        setShowLoader(true);

        const uploadRequest: ParcoursRisUploadRequest = {
          ris: uploadedFile,
        };

        dispatch(postParcoursRisUpload(uploadRequest));
      }
    } else if (!errorMessage) {
      setErrorMessage("upload.file.erreur.empty");
    }
  };

  // UseEffect déclenché suite à l'appel Ris Upload
  useEffect(() => {
    if (
      hasFetchedParcoursRisUpload(parcoursRisUploadState) &&
      parcoursRisUploadState.parcoursRisUpload.processed
    ) {
      saveEvent(trackingEvent.RIS_ANALYSE_OK);
      setShowLoader(false);
      dispatch(nextStep());
    } else if (parcoursRisUploadState.parcoursRisUploadErrorMessage) {
      manageErrorApi();
    }
  }, [parcoursRisUploadState]);

  // Gére l'affichage de la popin d'erreur
  const manageErrorApi = () => {
    saveEvent(
      trackingEvent.RIS_ANALYSE_KO,
      `[${parcoursRisUploadState.parcoursRisUploadErrorCode}] ${parcoursRisUploadState.parcoursRisUploadErrorMessage}`
    );
    setShowLoader(false);
    setShowErrorModal(true);
  };

  const retourMonBilan = () => {
    trackClick("preparation::clic-sur-retour-tableau-de-bord");
    history.push(DASHBOARD);
  };

  const getErrorMessage = () => {
    if (
      parcoursRisUploadState.parcoursRisUploadErrorMessage ===
      "unable_to_process_ris"
    ) {
      return "modal.error.message.ris.regimeNonPrisEnCharge";
    }
    if (
      parcoursRisUploadState.parcoursRisUploadErrorMessage ===
      "invalid_document"
    ) {
      return "modal.error.message.ris.docNonConforme";
    }
    if (errorFormat) {
      return "modal.error.message.ris.docInvalide";
    }

    return "modal.error.message";
  };

  return (
    <>
      <SGGridRow justify="center" gutter={["lg", "sm"]}>
        <SGGridCol span={12}>
          <SGTitle textalign="center" level={2}>
            {intl.formatMessage({ id: "parcoursRIS.preparation.title" })}
          </SGTitle>
        </SGGridCol>
        <SGGridCol span={9} textalign="center">
          <SGText size="m">
            {" "}
            {intl.formatMessage({
              id: "parcoursRIS.preparation.text.description",
            })}
          </SGText>
        </SGGridCol>
        <SGGridCol span={12}>
          <SGGridRow justify="center" align="stretch">
            <SGGridCol span={12} sm={6} lg={{ span: 5, offset: 1 }}>
              <ParcoursRISCarteImport
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                onSubmit={onSubmit}
                setUploadedFile={setUploadedFile}
              />
            </SGGridCol>
            <SGGridCol span={12} sm={6} lg={5}>
              <ParcoursRISCarteCommentFaire />
            </SGGridCol>
          </SGGridRow>
        </SGGridCol>
        <SGGridCol span={12}>
          <SGBox margin={{ top: "xl" }}>
            <SGSpace direction="vertical" size="xl">
              <SGTitle key="title" textalign="center" level={2}>
                <FormattedMessage id="parcoursRIS.preparation.title2" />
              </SGTitle>
              <ParcoursRISCarteVideo key="video" />
            </SGSpace>
          </SGBox>
        </SGGridCol>
        <SGGridCol span={12}>
          <SGBox margin={{ top: "md", bottom: "sm" }} textalign="center">
            <SGButton
              type="link"
              onClick={retourMonBilan}
              ariaLabel={intl.formatMessage({
                id: "parcoursRIS.preparation.link.retourBilanRetraite.ariaLabel",
              })}
            >
              <FormattedMessage id="parcoursRIS.preparation.link.retourBilanRetraite" />
            </SGButton>
          </SGBox>
        </SGGridCol>
      </SGGridRow>
      {showLoader && <LoaderParcoursRis showLoader={showLoader} />}
      {showErrorModal && (
        <ErrorModal
          visible={showErrorModal}
          setVisible={setShowErrorModal}
          displayRetry
          description={getErrorMessage()}
        />
      )}
    </>
  );
};

export { ParcoursRISPreparation };

import { FC, useState } from "react";
import { useIntl } from "react-intl";
import { SGAvenirStyledEpargne } from "sg-icon-pack-base";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { DrawerSolutions } from "website/components/organisms/Dashboard/CarouselNavigator/DrawerSolutions";
import { TuileCliquable } from "../TuileCliquable/TuileCliquable";

interface TuileSolutionsEpargneProps {
  prefixeTracking: string;
}

export const TuileSolutionsEpargne: FC<TuileSolutionsEpargneProps> = ({
  prefixeTracking,
}) => {
  const intl = useIntl();
  const { trackClick } = useTracking();

  const [showDrawerSolutions, setShowDrawerSolutions] = useState(false);

  return (
    <>
      <TuileCliquable
        icone={<SGAvenirStyledEpargne />}
        iconeTaille="s"
        titre={intl.formatMessage({
          id: "sectionAllerPlusLoin.tuile.solutionsEpargne.titre",
        })}
        texte={intl.formatMessage({
          id: "sectionAllerPlusLoin.tuile.solutionsEpargne.texte",
        })}
        onClick={() => {
          trackClick(`${prefixeTracking}::clic-sur-nos-solutions-d-epargne`);
          setShowDrawerSolutions(true);
        }}
        alignchildren="center"
      />
      <DrawerSolutions
        showDrawer={showDrawerSolutions}
        setShowDrawer={setShowDrawerSolutions}
        prefixeTracking={`${prefixeTracking}::`}
      />
    </>
  );
};

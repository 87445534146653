import { FunctionComponent, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import {
  SGAvenirNavFlecheBtnLien,
  SGAvenirStyledCorbeille,
} from "sg-icon-pack-base";
import { SGContent, SGLayout } from "sg-layout";
import { SGMediaQuery, useMediaQuery } from "sg-media-query";
import { SGBox, SGSpace } from "sg-space";
import { SGText, SGTitle } from "sg-typo";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { AlertDeleteProfileModal } from "website/components/molecules/AlertDeleteProfileModal/AlertDeleteProfileModal";
import { TuileCliquable } from "website/components/molecules/TuileCliquable/TuileCliquable";
import { DASHBOARD } from "website/utils/privateRoutes";
import { BreadCrumbAssu } from "../components/molecules/BreadCrumbAssu/BreadCrumbAssu";

const ModifierProfilPage: FunctionComponent = () => {
  const history = useHistory();

  const { trackClick, trackPage } = useTracking();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });

  const [isAlertDeleteProfileModalVisible, setAlertDeleteProfileModalVisible] =
    useState(false);

  useEffect(() => {
    trackPage("modifier-mon-profil", "accueil", "gestion-ecran");
  }, []);

  const handleOnClickLinkDashboard = () => {
    trackClick("retour-au-tableau-de-bord");
    history.push(DASHBOARD);
  };

  const handleOnClickOpenModal = () => {
    setAlertDeleteProfileModalVisible(true);
    trackClick("supprimer-mes-donnees");
  };

  return (
    <SGLayout background="light">
      <SGMediaQuery minwidth={0} maxwidth="md">
        <SGBox padding={{ top: "xxl" }}/>
      </SGMediaQuery>
      <SGContent
        disableautomargin
        extra={
          <SGMediaQuery minwidth="md">
            <SGBox textalign="left" span={12} margin={{ top: "xxs" }}>
              <SGGridRow>
                <SGGridCol span={12}>
                  <BreadCrumbAssu step="modifierProfil.header" />
                </SGGridCol>
              </SGGridRow>
            </SGBox>
          </SGMediaQuery>
        }
      >
        <SGSpace direction="vertical" align="center" textalign="center">
          <SGTitle key="title" level={1} visuallevel={1}>
            <FormattedMessage id="modifierProfil.titre"/>
          </SGTitle>
          <SGText key="text" size="l">
            <FormattedMessage id="modifierProfil.sousTitre"/>
          </SGText>
        </SGSpace>

        <TuileCliquable
          width={isPhone ? "null" : "auto"}
          icone={<SGAvenirStyledCorbeille />}
          iconeTaille="s"
          titre="Supprimer mes données"
          texte="Demander la suppression des données de simulation Mon Compagnon Retraite"
          onClick={handleOnClickOpenModal}
          ariaLabel="Supprimer mes données"
        />

        <AlertDeleteProfileModal
          isVisible={isAlertDeleteProfileModalVisible}
          setVisible={setAlertDeleteProfileModalVisible}
        />

        <SGMediaQuery minwidth={0} maxwidth="sm">
          <SGBox margin={{ top: "xxl" }} textalign="center">
            <SGButton
              type="tertiary"
              icon={<SGIcon currentcolor component={<SGAvenirNavFlecheBtnLien />} />}
              iconposition="right"
              onClick={handleOnClickLinkDashboard}
            >
              <FormattedMessage id="modifierProfil.retour"/>
            </SGButton>
          </SGBox>
        </SGMediaQuery>
      </SGContent>
      <SGBox margin={{ top: "xxl" }} padding={{ top: "xxl", bottom: "xxl" }}/>
    </SGLayout>
  );
};

export { ModifierProfilPage };

import { FunctionComponent, useContext, useEffect, useRef, useState } from "react";
import { SGCarousel } from "sg-carousel";
import { useMediaQuery } from "sg-media-query";
import { InfoCard, InfoCardType } from "../InfoCard/InfoCard";
import { DrawerSolutions } from "./DrawerSolutions";

const CarouselNavigator: FunctionComponent = () => {
    const [showDrawerSolutions, setShowDrawerSolutions] = useState(false);

    const cardsMobile = [InfoCardType.nosSolutions, InfoCardType.monPlan, InfoCardType.mesServices, InfoCardType.saviezVous, InfoCardType.mesArticles];
    const cardsDesktop = [InfoCardType.nosSolutions, InfoCardType.monPlan, InfoCardType.mesServices, InfoCardType.saviezVous];

    // fait référence au carousel pour pouvoir utiliser plusieurs méthodes
    const myRef = useRef<any>();

    const isPhone = useMediaQuery({ minwidth: 'xs', maxwidth: 'xs' });

    const carouselAction = (cardType: InfoCardType) => {
        if (cardType === InfoCardType.nosSolutions) {
            setShowDrawerSolutions(true);
        }
    };

    return (
        <>
            <SGCarousel
                // référence
                ref={myRef}
                slidesToScroll={1}
                settings={{
                    infinite: true,
                    slidesToShow: isPhone ? 1 : 2
                }}
            >
            {(isPhone ? cardsMobile : cardsDesktop).map((card) =>
                <InfoCard key={card} id={card} onCardAction={() => carouselAction(card)} />
            )}
            </SGCarousel>
            <DrawerSolutions showDrawer={showDrawerSolutions} setShowDrawer={setShowDrawerSolutions} />
        </>
    );
}

export { CarouselNavigator };

import { ReactComponent as Plus } from "assets/images/plusRed.svg";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { SGButton, SGButtonGroup } from "sg-button";
import { SGCard } from "sg-card";
import { SGGridCol, SGGridRow } from "sg-grid";
import { useMediaQuery } from "sg-media-query";
import { SGModal } from "sg-modal";
import { SGSelect, SGSelectOption } from "sg-select";
import { SGBox, SGSpace } from "sg-space";
import { SGBlock, SGText, SGTitle } from "sg-typo";
import { AccountState } from "store/account/types";
import { RetirementCategorySimulation } from "store/members/types";
import { updateParcoursRisInformationsComplementaires } from "store/parcoursRisInformationsComplementaires/actions";
import { ParcoursRisInformationsComplementairesState } from "store/parcoursRisInformationsComplementaires/types";
import { postParcoursRisProjection, resetParcoursRisProjectionError } from "store/parcoursRisProjection/actions";
import { ParcoursRisProjectionState, ParcoursRisResultsRequest, Projection, hasFetchedParcoursRisProjection } from "store/parcoursRisProjection/types";
import { ParcoursRisUploadState } from "store/parcoursRisUpload/types";
import { savePersonalInformations } from "store/personalInformations/action";
import { PersonalInformationsState } from "store/personalInformations/types";
import { State } from "store/store";
import { nextStepAction, previousStepAction } from "store/tunnel/actions";
import { saveWorkIncomes } from "store/workIncomes/action";
import { WorkIncome, WorkIncomePathType } from "store/workIncomes/types";
import { Loader } from "website/components/atoms/Loader/Loader";
import { SGTextIntl } from "website/components/atoms/SGTextIntl/SGTextIntl";
import { PAGE_CATEGORY_3_RIS, PAGE_TYPE_FORMULAIRE } from "website/components/hooks/tracking/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useFamily } from "website/components/hooks/useFamily";
import { useWorkIncomesHistory } from "website/components/hooks/useWorkIncomesHistory";
import { BlueCard } from "website/components/molecules/BlueCard/BlueCard";
import { ErrorModal } from "website/components/molecules/ErrorModal/ErrorModal";
import { InfoAssuModal } from "website/components/molecules/InfoAssuModal/InfoAssuModal";
import { StatutCard } from "website/components/molecules/StatutCard/StatutCard";
import "./ParcoursRISProjection.scss";

export const ParcoursRISProjection: FunctionComponent = () => {
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
    const [showCardsEmpty, setShowCardsEmpty] = useState<boolean>(false);
    const [addStatutsCard, setAddStatutsCard] = useState(false);
    const [cards, setCards] = useState<Projection[]>([]);
    const [itemSelected, setItemSelected] = useState<string>();
    const [selectedStatus, setSelectedStatus] = useState<number[]>([]);
    const [cardType, setCardType] = useState<number[]>([]);
    const [showCard, setShowCard] = useState<boolean>(true);
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [showPopin, setShowPopin] = useState(false);
    const [hasSeenInfo, setHasSeenInfo] = useState(false);
    const [montant, setMontant] = useState<number>();

    const allType = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 20, 21, 22, 131];

    const parcoursRisProjectionState: ParcoursRisProjectionState = useSelector<State, ParcoursRisProjectionState>((state) => state.parcoursRisProjection);
    const parcoursRisInformationsComplementairesState: ParcoursRisInformationsComplementairesState = useSelector<
        State,
        ParcoursRisInformationsComplementairesState
    >((state) => state.parcoursRisInformationsComplementaires);
    const parcoursRisUploadState: ParcoursRisUploadState = useSelector<State, ParcoursRisUploadState>((state) => state.parcoursRisUpload);
    const accountState: AccountState = useSelector<State, AccountState>((state) => state.account);
    const personalInformationsState: PersonalInformationsState = useSelector<State, PersonalInformationsState>((state) => state.personalInformations);

    const family = useFamily();
    const workIncomesHistory = useWorkIncomesHistory();
    const intl = useIntl();
    const dispatch = useDispatch();
    const { trackClick, trackPage } = useTracking();

    const methods = useForm({
        reValidateMode: "onChange",
        criteriaMode: "all",
        mode: "onSubmit",
    });

    const { handleSubmit } = methods;

    const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "md" });

    useEffect(() => {
        const projectionsCards: Projection[] = [];

        trackPage(PAGE_CATEGORY_3_RIS, "projection", PAGE_TYPE_FORMULAIRE, "simulation", "4");

        // Creation d'une liste de projections à partir de la liste de status récupérés
        parcoursRisInformationsComplementairesState.parcoursRisInformationsComplementaires?.acquis?.statuts.map((statutCard: number) => {
            updateCardList(projectionsCards, statutCard);

            return projectionsCards;
        });
        setCards(projectionsCards);
        const statutsCopy = parcoursRisInformationsComplementairesState.parcoursRisInformationsComplementaires?.acquis?.statuts.map((statut) => statut);
        setSelectedStatus(statutsCopy);
        setCardType(filterStatus());
    }, []);

    useEffect(() => {
        setCardType(filterStatus());
    }, [selectedStatus]);

    const addCard = (statutCard: number) => {
        setAddStatutsCard(false);
        if (!selectedStatus.includes(statutCard)) {
            setShowCardsEmpty(false);
            selectedStatus.push(statutCard);
            updateCardList(cards, statutCard);
            setCardType(filterStatus());
        }
        setItemSelected("");
    };

    const deleteCard = (statutCard: number): void => {
        trackClick("projection::clic-sur-la-corbeille");
        if (cards.length <= 1) {
            setShowCardsEmpty(true);
        }
        setCards(cards.filter((card) => card.statut !== statutCard));
        setSelectedStatus(selectedStatus.filter((statut) => statut !== statutCard));
    };

    const openAddPopin = () => {
        trackClick("projection::clic-sur-ajouter-un-statut");
        setAddStatutsCard(true);
    };

    /**
     * on vérifie si un montant anormalement faible a été saisi dans une des cards
     */
    const hasLowIncome = () => {
        let lowIncome: number;

        const filteredCards = cards.filter((card: Projection) => {
            let income;

            if (card.statut !== 6 && card.statut !== 7) {
                income = card.remuneration_annuelle;
            } else if (card.remuneration_mensuelle) {
                income = card.remuneration_mensuelle * 12;
            }

            const hasLowInc = income !== undefined && income > 0 && income < 10000;

            // On ne garde que le plus petit montant
            if (hasLowInc && income !== undefined && (lowIncome === undefined || lowIncome > income)) {
                lowIncome = income;
                setMontant(income);
            }

            return hasLowInc;
        });

        return filteredCards.length > 0;
    };

    const onSubmit = () => {
        // On affiche une popin d'info si un des montants renseignés est anormalement faible
        if (!hasLowIncome() || hasSeenInfo) {
            trackClick("projection::clic-sur-suivant");
            setShowLoader(true);
            dispatch(resetParcoursRisProjectionError());

            if (family.me) {
                const workIncomes: WorkIncome[] = cards.map((projection: Projection) => ({
                    userId: accountState.account.id,
                    originPath: WorkIncomePathType.RIS,
                    profession: projection.statut,
                    income: projection.remuneration_annuelle ?? projection.remuneration_mensuelle ?? 0,
                }));
                dispatch(saveWorkIncomes(workIncomes));

                const parcoursRisProjectionRequest: ParcoursRisResultsRequest = {
                    memberId: family.me.id || 0,
                    userId: accountState.account.id,
                    projections: cards,
                    parcoursKey: parcoursRisUploadState.parcoursRisUpload.parcours_key,
                    acquis: {
                        trimestresAcquis: parcoursRisInformationsComplementairesState.parcoursRisInformationsComplementaires?.acquis?.trimestres_acquis,
                        trimestresRestants: parcoursRisInformationsComplementairesState.parcoursRisInformationsComplementaires?.acquis?.trimestres_restants,
                        dateTrimestreRestants:
                            parcoursRisInformationsComplementairesState.parcoursRisInformationsComplementaires?.acquis?.date_trimestre_restants,
                        dateDepart: parcoursRisInformationsComplementairesState.parcoursRisInformationsComplementaires?.acquis?.date_depart,
                    },
                    ancienneSimulation: {
                        montantAnnuelSalaire: workIncomesHistory.currentYearlyAmount,
                        evolSalaireFutur: workIncomesHistory.expectedYearlyIncrease,
                        secteurPro: family.me.retirementType && RetirementCategorySimulation[family.me.retirementType],
                    },
                };

                dispatch(postParcoursRisProjection(parcoursRisProjectionRequest));

                // Enregistrement des données de l'étape 3 en BDD
                dispatch(savePersonalInformations(personalInformationsState.personalInformations));
            }
        } else {
            setShowPopin(true);
        }
    };

    useEffect(() => {
        if (hasFetchedParcoursRisProjection(parcoursRisProjectionState) && parcoursRisProjectionState.parcoursRisProjection.date_parcours != null) {
            dispatch(nextStepAction());
        } else if (parcoursRisProjectionState.parcoursRisProjectionError) {
            setShowErrorModal(true);
        }
    }, [dispatch, parcoursRisProjectionState]);

    const handlePreviousButton = () => {
        dispatch(
            updateParcoursRisInformationsComplementaires({
                ...parcoursRisInformationsComplementairesState,
                success: false,
            })
        );
        trackClick("projection::clic-sur-retour");
        dispatch(previousStepAction());
    };

    function filterStatus() {
        let types = allType;
        selectedStatus.forEach((statut: number) => {
            types = types.filter((type) => type !== statut);
        });

        return types;
    }

    function updateCardList(cardsList: Projection[], statutCard: number) {
        let remunerationType;
        if (statutCard === 1 || statutCard === 2 || statutCard === 3) {
            remunerationType = "brut";
        } else if (statutCard === 21) {
            remunerationType = "net";
        }

        // On initialise à 0 dans les champs adéquats
        cardsList.push({
            statut: statutCard,
            remuneration_annuelle: statutCard !== 6 && statutCard !== 7 ? 0 : undefined,
            remuneration_mensuelle: statutCard === 6 || statutCard === 7 ? 0 : undefined,
            prime_mensuelle: statutCard === 6 || statutCard === 7 ? 0 : undefined,
            remuneration_type: remunerationType,
            cavec_salarie: statutCard === 9 ? false : undefined,
        });
    }

    const onHideModal = () => {
        setShowPopin(false);
        setHasSeenInfo(true);
    };

    return (
        <SGCard shadow={false} disableautomargin>
            <SGBox margin={{ left: "sm", right: "sm" }}>
                <SGGridRow justify="center">
                    <SGGridCol span={12} xl={9}>
                        <SGGridRow gutter={[0, 12]}>
                            <SGGridCol span={12}>
                                <SGBox margin={{ top: "md" }}>
                                    <SGTitle textalign="left" level={2}>
                                        {intl.formatMessage({ id: "parcoursRIS.projection.title" })}
                                    </SGTitle>
                                </SGBox>
                            </SGGridCol>
                            <SGGridCol span={12}>
                                <SGText size="l">
                                    <FormattedMessage id="parcoursRIS.projection.subtitle" />
                                </SGText>
                            </SGGridCol>
                            <SGGridCol span={12}>
                                <BlueCard text="projection" showCard={showCard} setShowCard={setShowCard} />
                            </SGGridCol>

                            <SGGridCol span={12}>
                                <SGSpace direction="vertical" disableautomargin>
                                    <SGBlock key="block">
                                        <SGBox margin={{ top: "md", bottom: "xxs" }}>
                                            <SGTitle textalign="left" level={3}>
                                                {intl.formatMessage({ id: "parcoursRIS.projection.section" })}
                                            </SGTitle>
                                        </SGBox>
                                    </SGBlock>
                                    <FormProvider key="form" {...methods}>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <SGGridRow justify="center">
                                                <SGGridCol span={12} sm={10} md={9} xl={12} xxl={9}>
                                                    {cards.length > 0 &&
                                                        cards.map((card: Projection, index: number) => (
                                                            <SGBox key={card.statut} disableautomargin={index === 0} margin={{ top: isPhone ? "md" : "sm" }}>
                                                                <StatutCard {...methods} card={card} deleteCard={deleteCard} />
                                                            </SGBox>
                                                        ))}
                                                    <SGBox margin={{ top: isPhone ? "md" : "sm" }}>
                                                        <SGCard clickable disableautomargin>
                                                            <SGButton
                                                                type="link"
                                                                underline={false}
                                                                icon={<Plus />}
                                                                onClick={(event: Event) => {
                                                                    event.preventDefault();
                                                                    openAddPopin();
                                                                }}
                                                                ariaLabel={intl.formatMessage({ id: "parcoursRIS.projection.ajout.card.ariaLabel" })}
                                                            >
                                                                <FormattedMessage id="parcoursRIS.projection.ajout.card" />
                                                            </SGButton>
                                                        </SGCard>
                                                    </SGBox>
                                                    {showCardsEmpty && (
                                                        <SGBlock>
                                                            <SGText type="error" color="error">
                                                                <FormattedMessage id="parcoursRIS.projection.cards.empty" />
                                                            </SGText>
                                                        </SGBlock>
                                                    )}
                                                    {!showCardsEmpty && Object.keys(methods.errors).length !== 0 && (
                                                        <SGBlock>
                                                            <SGText type="error" color="error">
                                                                <FormattedMessage id="parcoursRIS.projection.cards.errors" />
                                                            </SGText>
                                                        </SGBlock>
                                                    )}
                                                    {addStatutsCard && (
                                                        <SGModal
                                                            className="parcours-ris-projection__ajout-statuts-card"
                                                            visible={addStatutsCard}
                                                            onCancel={() => setAddStatutsCard(false)}
                                                            centered
                                                            closable
                                                            header={
                                                                <SGGridRow>
                                                                    <SGGridCol align="start">
                                                                        <SGText size="xxl">
                                                                            <FormattedMessage id="parcoursRIS.projection.ajout.card" />
                                                                        </SGText>
                                                                    </SGGridCol>
                                                                </SGGridRow>
                                                            }
                                                        >
                                                            <SGGridRow>
                                                                <SGGridCol align="start" span={12}>
                                                                    <SGSelect
                                                                        disableautomargin
                                                                        placeholder=""
                                                                        label={
                                                                            <SGText>
                                                                                <FormattedMessage id="parcoursRIS.projection.select.statuts" />
                                                                            </SGText>
                                                                        }
                                                                        value={itemSelected || ""}
                                                                        onChange={(value: string) => {
                                                                            setItemSelected(value);
                                                                        }}
                                                                    >
                                                                        <SGSelectOption value="" style={{ display: "none" }}>
                                                                            {intl.formatMessage({
                                                                                id: "parcoursRIS.projection.ajout.card",
                                                                            })}
                                                                        </SGSelectOption>
                                                                        {cardType.map((index: number) => (
                                                                            <SGSelectOption key={index} value={index.toString()}>
                                                                                {intl.formatMessage({
                                                                                    id: `parcoursRIS.projection.statut.${index}`,
                                                                                })}
                                                                            </SGSelectOption>
                                                                        ))}
                                                                    </SGSelect>
                                                                </SGGridCol>

                                                                <SGGridCol textalign="right" style={{ marginTop: "20px" }} span={12}>
                                                                    <SGButton
                                                                        type="primary"
                                                                        onClick={() => addCard(Number(itemSelected))}
                                                                        disabled={!itemSelected}
                                                                        data-cy="parcours-ris-projection-submit"
                                                                        ariaLabel={intl.formatMessage({ id: "common.add.ris.ariaLabel" })}
                                                                    >
                                                                        <FormattedMessage id="common.add" />
                                                                    </SGButton>
                                                                </SGGridCol>
                                                            </SGGridRow>
                                                        </SGModal>
                                                    )}
                                                    <SGBlock>
                                                        <SGBox margin={{ top: "md", bottom: "sm" }}>
                                                            {showLoader ? (
                                                                <SGGridCol span={12} textalign="center">
                                                                    <Loader title="loader.title" />
                                                                </SGGridCol>
                                                            ) : (
                                                                <SGButtonGroup align={isPhone ? "center" : "opposite"}>
                                                                    <SGButton
                                                                        size="sm"
                                                                        onClick={() => !showCardsEmpty && handleSubmit(onSubmit)()}
                                                                        ariaLabel={intl.formatMessage({ id: "common.next.ariaLabel" })}
                                                                        data-cy="parcours-ris-projection-submit"
                                                                    >
                                                                        <SGTextIntl intlId="common.next" />
                                                                    </SGButton>
                                                                    <SGButton
                                                                        type="link"
                                                                        onClick={handlePreviousButton}
                                                                        ariaLabel={intl.formatMessage({ id: "common.previous.ariaLabel" })}
                                                                    >
                                                                        <SGTextIntl intlId="common.previous" />
                                                                    </SGButton>
                                                                </SGButtonGroup>
                                                            )}
                                                        </SGBox>
                                                    </SGBlock>
                                                </SGGridCol>
                                            </SGGridRow>
                                        </form>
                                    </FormProvider>
                                </SGSpace>
                            </SGGridCol>
                        </SGGridRow>
                        <ErrorModal visible={showErrorModal} setVisible={setShowErrorModal} />
                        <InfoAssuModal
                            onHide={onHideModal}
                            showInfoModal={showPopin}
                            text="popin.info.montant.text"
                            tranform={{ montant: montant?.toLocaleString("fr-FR") }}
                            title="modal.error.info.title"
                            buttonPrimary="modal.error.button"
                        />
                    </SGGridCol>
                </SGGridRow>
            </SGBox>
        </SGCard>
    );
};

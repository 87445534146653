import { FunctionComponent, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { SGButton } from "sg-button";
import { SGGridCol, SGGridRow } from "sg-grid";
import { SGIcon } from "sg-icon";
import { SGAvenirNavFlecheBtnLien } from "sg-icon-pack-base";
import { useMediaQuery } from "sg-media-query";
import { SGBox } from "sg-space";
import { SGTag } from "sg-tag";
import { SGText } from "sg-typo";
import { savePromotionalOffer } from "store/promotionalOffers/action";
import { PromotionalOffer } from "store/promotionalOffers/types";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { useOpenLink } from "website/components/hooks/useOpenLink";
import { usePromotionalOffers } from "website/components/hooks/usePromotionalOffers";
import { externalURL } from "website/utils/externalUrl";
import { ErrorModal } from "../ErrorModal/ErrorModal";
import "./PromotionalBanner.scss";

export enum OffreType {
  PER = "PER",
}

interface PromotionalBannerProps {
  offreType: OffreType;
}

const PromotionalBanner: FunctionComponent<PromotionalBannerProps> = (
  props: PromotionalBannerProps
) => {
  const { offreType } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const { offrePER } = usePromotionalOffers();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const { trackClick } = useTracking();

  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const openLink = useOpenLink();

  const onClickLink = () => {
    if (offreType === OffreType.PER) {
      trackClick("banniere-per::clic-sur-en-savoir-plus");
      // Accepter l'offre PER via la bannière promotionnelle
      const promotionalOffer: PromotionalOffer = {
        ...offrePER,
        lastOfferDate: new Date(),
        acceptOfferDate: new Date(),
      };
      dispatch(savePromotionalOffer(promotionalOffer));

      openLink(externalURL.EXTERNAL_URL_PER_ACACIA);
    }
  };

  const styles = {
    backgroundImage: `url("images/dashboard/offre-${offreType}-banner${
      !isPhone ? "-desktop" : ""
    }.${offreType === OffreType.PER ? "svg" : "png"}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    borderRadius: "10px"
  };

  return (
      <div className="promotional-banner">
        <SGBox style={styles} margin={{top: "sm"}}>
          <SGBox
            padding={
              isPhone ? { top: "md", bottom: "md", left: "sm", right: "sm" } : { top: "sm", bottom: "sm", left: "sm", right: "sm" }
            }
          >
            <SGGridRow gutter={[0, 5]} align="bottom">
              <SGGridCol span={12}>
                <SGTag tagcolor="20">
                  <SGText>
                    {intl.formatMessage(
                      {
                        id: `dashboard.banner.tag.${offreType}`,
                      },
                      {
                        desktop: (word: string) => !isPhone && word,
                      }
                    )}
                  </SGText>
                </SGTag>
              </SGGridCol>
              <SGGridCol span={12}>
                <SGText weight="600" color="lightest">
                  {intl.formatMessage({
                    id: `dashboard.banner.title.${offreType}${
                      isPhone && offreType === OffreType.PER ? ".mobile" : ""
                    }`,
                  })}
                </SGText>
              </SGGridCol>
              <SGGridCol flex="auto">
                <SGText size="xs" color="lightest" whitespace="pre-line">
                  {intl.formatMessage({
                    id: `dashboard.banner.subtitle.${offreType}`,
                    defaultMessage: " ",
                  })}
                </SGText>
              </SGGridCol>
              <SGGridCol flex="auto" textalign="right">
                <SGButton
                  icon={
                    <SGIcon
                      component={<SGAvenirNavFlecheBtnLien />}
                      currentcolor
                    />
                  }
                  onClick={onClickLink}
                  type="icon"
                  iconposition="right"
                >
                  {intl.formatMessage({
                    id: `dashboard.banner.link.${offreType}`,
                  })}
                </SGButton>
              </SGGridCol>
            </SGGridRow>
          </SGBox>
        </SGBox>
        {showErrorModal && (
          <ErrorModal
            visible={showErrorModal}
            setVisible={setShowErrorModal}
            description="modal.error.message.indisponible"
          />
        )}
      </div>
    );
};

export { PromotionalBanner };

import { FunctionComponent } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { SGInputQuantity } from "sg-input";
import { SGText } from "sg-typo";
import { InfoTooltipAssu } from "../InfoTooltipAssu/InfoTooltipAssu";
import { useMediaQuery } from "sg-media-query";

interface ChildrenCounterProps {
  counter: number;
  setCounter: (value: number) => void;
  min?: number;
  max?: number;
  name: string;
}

const ChildrenCounterAssu: FunctionComponent<ChildrenCounterProps> = (
  props: ChildrenCounterProps
) => {
  const { counter, min = 0, max = 9, setCounter, name } = props;

  const intl = useIntl();
  const methods = useFormContext();
  const isPhone = useMediaQuery({ minwidth: "xs", maxwidth: "xs" });
  const increment = 1;

  return (
    <Controller
      control={methods.control}
      name={name}
      rules={{
        required: true,
      }}
      defaultValue={counter}
      render={({ onChange }) => (
        <SGInputQuantity
          label={
            <SGText size="l">
              {intl.formatMessage(
                { id: "tunnel.situation.nombreEnfants" },
                {
                  tooltip: (
                    <InfoTooltipAssu
                      text="parcoursRIS.complements.situationPerso.enfants.tooltip"
                      transformations={{ max: max.toString() }}
                      placement={isPhone ? "bottomRight" : "right"}
                    />
                  ),
                }
              )}
            </SGText>
          }
          value={counter}
          increment={increment}
          onDecrement={() => {
            const val = counter - increment;
            setCounter(val);
            onChange(val);
          }}
          onIncrement={() => {
            const val = counter + increment;
            setCounter(val);
            onChange(val);
          }}
          onChange={(value: number) => {
            onChange(value);
            setCounter(value);
          }}
          unitvalue=""
          inputMode="numeric"
          size="s"
          max={max}
          min={min}
          status={methods.errors?.childrenCounter?.types?.required && "error"}
          validate={intl.formatMessage({ id: "tunnel.error.required" })}
        />
      )}
    />
  );
};

export { ChildrenCounterAssu };

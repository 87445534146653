import { ComponentType } from "react";
import { useHistory } from "react-router";
import { useTracking } from "website/components/hooks/tracking/useTracking";
import { TuileCliquable } from "../TuileCliquable/TuileCliquable";

interface TuileServiceAccompagnementProps {
  titre: string;
  texte: string;
  lien: string;
  Icon: ComponentType;
  trackKey: string;
}

export const TuileServiceAccompagnement = ({
  titre,
  texte,
  lien,
  Icon,
  trackKey,
}: TuileServiceAccompagnementProps) => {
  const history = useHistory();
  const { trackClick } = useTracking();

  const handleOnClick = () => {
    trackClick(trackKey);
    history.push(lien);
  };

  return (
    <TuileCliquable
      icone={<Icon />}
      iconeTaille="m"
      titre={titre}
      texte={texte}
      onClick={handleOnClick}
    />
  );
};
